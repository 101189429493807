import { Component, OnInit,Input } from '@angular/core';
import { NgForm,FormControl, FormGroup, Validators,ValidatorFn,FormGroupDirective,ValidationErrors } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { GlobalVariableService } from '../../services/global-variable.service';
import { StorageService } from '../../services/storage.service';
import Swal from 'sweetalert2';
import {ErrorStateMatcher} from '@angular/material/core';
import {uniquecomboValidator} from '../../validators/uniquecombo.validator';
import {DategenService} from '../../services/dategen.service';
import {ErrorhandleapiService} from '../../services/errorhandleapi.service';

export class InstantErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null,
               form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}



@Component({
  selector: 'app-single-reflection-page-component',
  templateUrl: './single-reflection-page-component.component.html',
  styleUrls: ['./single-reflection-page-component.component.scss']
})
export class SingleReflectionPageComponentComponent implements OnInit {

  @Input()
  PageID: number = -1;

  @Input()
  ReflectionTitle: string = "";

  @Input()
  SubmitText: string = "Submit";

  @Input()
  RouteBack: string = "/reflection/reflection1";

  @Input()
  RouteSubmit: string = "/qcard/mtbtradingstyle2";

  @Input()
  FilterObjects: any;

  @Input()
  UniqueCombo: boolean = false;

  @Input()
  CoachComments: boolean = false;

  @Input()
  CoachCommentsTitle: string = "Facilitators Comment";

  @Input()
  LineAfterQuestion: any = [];

  PrimaryTradingStyleForm:any;

  validation_messages = {}

  matcher = new InstantErrorStateMatcher();

  private questions: any;
  private mbtradingdata: any;
  private pagedesc:string="";

  loadingBar: boolean = true;
  isMTB: boolean = false;
  isActive:boolean = true;
  isSubmit: boolean = true;


  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public route: Router,
    private dategenService: DategenService,
    private errorapi:ErrorhandleapiService
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

    this.validation_messages = {}
    this.PrimaryTradingStyleForm = null;
  }

  onBack() {
    this.route.navigateByUrl(this.RouteBack);
  }

  updatePolarValue(formitem,e)
  {
    this.PrimaryTradingStyleForm.get(formitem).setValue(e);
    this.PrimaryTradingStyleForm.get(formitem).markAsDirty();
  }

  dateProcess(datestring)
  {
    this.dategenService.dateProcess(datestring,"medium","Last Updated At ", "");
  }

  getFormValidationErrors() {

    console.log('%c ==>> Validation Errors: ', 'color: red; font-weight: bold; font-size:25px;');

    let totalErrors = 0;

    Object.keys(this.PrimaryTradingStyleForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.PrimaryTradingStyleForm.get(key).errors;
      if (controlErrors != null) {
         totalErrors++;
         Object.keys(controlErrors).forEach(keyError => {
           console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
      }
    });

    console.log('Number of errors: ' ,totalErrors);
  }


  processReflectionArguments(questionIndex)
  {
    let i=questionIndex;
    if (this.questions[i].question.includes("#line#"))
    {
      this.questions[i].question = this.questions[i].question.replace("#line#","");
      this.questions[i].drawline = true;
    }

    if (this.questions[i].question.includes("#nocomment#"))
    {
      this.questions[i].question = this.questions[i].question.replace("#nocomment#","");
      this.questions[i].nocomment = true;
    }

    if (this.questions[i].question.includes("#normalfont#"))
    {
      this.questions[i].question = this.questions[i].question.replace("#normalfont#","");
      this.questions[i].normalfont = true;
    }

    if (this.questions[i].question.includes("#readonly#"))
    {
      this.questions[i].question = this.questions[i].question.replace("#readonly#","");
      this.questions[i].readonly = true;
    }
  }

  processData() {
    if (this.mbtradingdata.length>0)
    {
      this.pagedesc = this.mbtradingdata[0].description;
      this.questions = Array.from(this.mbtradingdata[0].questions.reduce((m, t) => m.set(t.id, t), new Map()).values());
      if (this.mbtradingdata[0].name.includes("#coach#"))
      {
        this.CoachComments = true;
        this.mbtradingdata[0].name = this.mbtradingdata[0].name.replace("#coach#","");
      }

      let group={}
      for (let i=0;i<this.questions.length;i++)
      {
        this.processReflectionArguments(i);
        if (this.questions[i].type=="TITLE")
          this.questions[i].nocomment = true;
        else if (this.questions[i].type=="RANGE")
          group['mtbquestion'+this.questions[i].id] = new FormControl('',[Validators.min(0),Validators.required]);
        else
        {
          if (this.questions[i].question.includes("#optional#"))
          {
            group['mtbquestion'+this.questions[i].id] = new FormControl('');
            this.questions[i].question = this.questions[i].question.replace("#optional#","");
          }
          else
            if (!(this.questions[i].question.includes("#optional#")))
              group['mtbquestion'+this.questions[i].id] = new FormControl('',Validators.required);
        }

      }

      if (this.UniqueCombo)
        this.PrimaryTradingStyleForm = new FormGroup(group,{validators:uniquecomboValidator(this.questions)});
      else
        this.PrimaryTradingStyleForm = new FormGroup(group);

      this.questions.forEach(question=>{
        if (question.type=="TITLE") return;
        if (question.feedback.value !=null)
        {
          if (question.type=="SELECT")
            this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.feedback.selection_id);
          else
            this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.feedback.value);
        }
        else
          if (question.type=="RANGE")
            this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.range.min);
        this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).updateValueAndValidity();

        let itemArray = Array();
        let item = {"type":"required","message":question.question + " is required"};
        itemArray.push(item);

        if (question.type=="RANGE")
        {
          item["type"] = "min";
          item["message"] = question.question + " needs to be more than 0";
        }

        this.validation_messages['mtbquestion'+question.id] = itemArray;

      })
      this.getFormValidationErrors();
    }
    else
      this.isActive = false;

  }


  ngAfterViewInit() {
    this.apiService.getReflections().subscribe(data => {
      //if (!this.authService.authCheckApi(data)) return;
      if (data.code === 0) {
        this.isMTB = true;
        this.mbtradingdata = data.result.filter(x => x.id == this.PageID);
        console.log(this.mbtradingdata);
        this.processData();
      }
      else
        this.isMTB = false;
      this.loadingBar = false;

    });
  }

  onSubmit() {
    var dataparam = Array();
    let qparam;
    let param;
    this.isSubmit=false;


    this.questions.forEach(question=>{

      var qparam;
      qparam = {
        question_id: question.id,
        selection_id: null,
        value: null
      };

      if (question.type=="SELECT")
      {
        let vfilter = question.selection.filter(x => x.id == this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value)[0].value;
        qparam["selection_id"] = this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value;
        qparam["value"] = vfilter;
      }
      else if (question.type=="RANGE")
        qparam["value"] = +this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value;
      else if (question.type=="TITLE")
        qparam["value"] = "title";
      else
        qparam["value"] = this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value;

      dataparam.push(qparam);

    })

    param =
    {
      page_id: this.mbtradingdata[0].id,
      feedbacks: dataparam

    }

    this.apiService.updateFeedback(param).subscribe(data => {
      this.isSubmit=true;
      if (data.code === 0) {
        // If there are requested fields

        Swal.fire({
          icon: 'success',
          title: this.pagedesc+' updated',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false
        });

        this.route.navigate([this.RouteSubmit]);
      }
      else {
        this.errorapi.handleError(data.errors);
      }
    });
  }

}
