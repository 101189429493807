import { Component, OnInit,Input } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-videoplayer[VideoURL]',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss']
})
export class VideoplayerComponent implements OnInit {

  @Input()
    VideoURL: string="";

  @Input()
    Title: string = "Undefined";

  Name: string = "Person Name";
  constructor(
    public storageService: StorageService,
    private apiService: ApiService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.apiService.getProfileDetails().subscribe(data => {
      console.log(data);
      //if (!this.authService.authCheckApi(data)) return;
      if (data.code==0)
      {
        console.log(data.result.email);
        this.Name = data.result.email;
      }

    });
    //this.Name = this.storageService.getStudentName();
  }

}
