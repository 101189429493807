import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { GlobalVariableService } from '../../services/global-variable.service';
import { StorageService } from '../../services/storage.service';
import Swal from 'sweetalert2';
import { ChartType } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {lifeWheelColors} from '../../shared/event-constants';


@Component({
  selector: 'app-life-wheel-single',
  templateUrl: './life-wheel-single.component.html',
  styleUrls: ['./life-wheel-single.component.scss']
})
export class LifeWheelSingleComponent implements OnInit {

  public polarAreaChartLabels: Label[] = ['Work/Career/Mission','Financial','Hobby/Passion/Fun','Relationship','Health','Growth/Personal Development','Contribution','Spiritual/Finding Meaning'];
  public polarAreaChartData: SingleDataSet = [1,1,1,1,1,1,1,1];
  public polarAreaLegend = true;
  public polarAreaChartType: ChartType = 'polarArea';
  public polarAreaPlugins = [pluginDataLabels];
  lwcolors:any;

  public options = {
    scale: {
      ticks: {
          beginAtZero: true,
          // stepSize: 1,
          min: 0,
          max: 10,
      },
    },
    plugins: {
      datalabels: {
        color: 'blue',
        labels: {
          label: {
          },
          value: {
          }
        }
      }
    }
  };

  @Input()
  PageDescription: string = "";

  @Input()
  ReflectionTitle: string = "";

  @Input()
  SubmitText: string = "Submit";

  @Input()
  RouteBack: string = "/qcard/reflection1";

  @Input()
  RouteSubmit: string = "/qcard/mtbtradingstyle2";

  @Input()
  LifeWheelPageName: string ="Life Wheel"

  PrimaryTradingStyleForm:any;

  validation_messages = {}

  private questions: any;
  private mbtradingdata: any;
  private lifewheeldata: any = Array();

  loadingBar: boolean = true;
  isMTB: boolean = false;
  isActive:boolean = true;


  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public route: Router
  ) { }

  ngOnInit(): void {
    this.lwcolors = lifeWheelColors;
  }

  ngOnDestroy(): void {

    this.validation_messages = {}
    this.PrimaryTradingStyleForm = null;
  }

  onBack() {
    this.route.navigateByUrl(this.RouteBack);
  }


  processData() {
    if (this.mbtradingdata.length>0)
    {
      this.questions = Array.from(this.mbtradingdata[0].questions.reduce((m, t) => m.set(t.id, t), new Map()).values());


      let group={}
      this.questions.forEach(question=>{
        if (question.type=="RANGE")
          group['mtbquestion'+question.id] = new FormControl('',[Validators.min(0),Validators.required]);
        else
          group['mtbquestion'+question.id] = new FormControl('',Validators.required);
      })
      this.PrimaryTradingStyleForm = new FormGroup(group);



      this.questions.forEach(question=>{
        if (question.feedback.value !=null)
        {
          if (question.type=="SELECT")
          {
            this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.feedback.selection_id);
          }
          else
          this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.feedback.value);
        }
        else
        {
          if (question.type=="RANGE")
            this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.range.min);
        }

        let itemArray = Array();
        let item = {};
        item["type"] = "required";
        item["message"] = question.question + " is required";
        itemArray.push(item);

        this.validation_messages['mtbquestion'+question.id] = itemArray;

      })
    }
    else
      this.isActive = false;

  }

  processLifeWheels(datalist)
  {
    console.log("In Life Wheels");
    console.log(datalist);
    let itemlist = datalist.filter(x => x.name === this.LifeWheelPageName);
    let filteredlifewheel = itemlist.filter(x => x.questions.length >=8);
    console.log (filteredlifewheel);

    for (let k=0;k<filteredlifewheel.length;k++)
    {
      let vpoints = Array();
      for (let j=0;j<filteredlifewheel[k].questions.length;j++)
      {
        if (filteredlifewheel[k].questions[j].feedback.value==null)
        {
          vpoints.push(0);
        }
        else
        {
          vpoints.push(+filteredlifewheel[k].questions[j].feedback.value);
        }
      }
      console.log(vpoints);

      let lifewheelitem =
      {
        description:filteredlifewheel[k].description,
        points:vpoints
      }

      this.lifewheeldata.push(lifewheelitem);

    }
  }


  ngAfterViewInit() {
    this.apiService.getReflections().subscribe(data => {
      //if (!this.authService.authCheckApi(data)) return;
      if (data.code === 0) {
        this.isMTB = true;
        this.mbtradingdata = data.result.filter(x => x.description === this.PageDescription);
        this.processData();
        this.processLifeWheels(data.result);
      }
      else {
        this.isMTB = false;
      }
      this.loadingBar = false;

    });
  }

  onSubmit() {
    var dataparam = Array();
    let qparam;
    let param;


    this.questions.forEach(question=>{
      var qparam;
        if (question.type=="SELECT")
        {
          let vfilter = question.selection.filter(x => x.id == this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value)[0].value;
          qparam = {
            question_id: question.id,
            selection_id: this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value,
            value: vfilter
          }
        }
        else if (question.type=="RANGE")
        {
          qparam = {
            question_id: question.id,
            selection_id: null,
            value: +this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value
          }
        }
        else {
          qparam = {
            question_id: question.id,
            selection_id: null,
            value: this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value
          }
        }
        dataparam.push(qparam);


    })

    param =
    {
      page_id: this.mbtradingdata[0].id,
      feedbacks: dataparam

    }

    this.apiService.updateFeedback(param).subscribe(data => {
      if (data.code === 0) {
        // If there are requested fields

        Swal.fire({
          icon: 'success',
          title: this.PageDescription+' updated',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false
        });

        this.route.navigate([this.RouteSubmit]);
      }
      else {
        var errorMessages = '';

        // If there are errors
        if (!!data.errors) {
          // Get array of error fields
          var arrayOfFields = Object.keys(data.errors);
          // Loop the array of error fields
          for (var i = 0; i < arrayOfFields.length; i++) {
            // Get the array of messages for each field
            var arrayOfMessages = data.errors[arrayOfFields[i]];
            for (var j = 0; j < arrayOfMessages.length; j++) {
              errorMessages += arrayOfMessages[j] + '\n';
            }
          }
        }

        Swal.fire({
          title: 'Error, kindly inform administrator',
          text: errorMessages,
          icon: 'warning',
          confirmButtonText: 'Okay',
          heightAuto: false
        });
      }
    });
  }
}
