import { Injectable } from '@angular/core';
import {Trader} from '../investors/traders';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { RequestHandlersService } from './request-handlers.service';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';

const endpoint: string = environment.apiUrl;
const stockEndpoint: string = environment.stockapiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TraderService {

  constructor(private http: HttpClient, private requestHandler: RequestHandlersService) { }

  trader : any;
  traders: any = [];
  filterTraders : any = [];
  id : number;

  getTraders(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  addNlv(value) : Observable<any> {
    return this.http.post(endpoint + 'stock_journals/nlv/add', value, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  updateNlv(id, value, fieldName) : Observable<any> {
    return this.http.post(endpoint + 'stock_journals/nlv/update/' + id, {value : value, fieldName : fieldName}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  archiveNlv(id) : Observable<any> {
    return this.http.put(endpoint + 'stock_journals/nlv/archive/' + id, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  activateNlv(id) : Observable<any> {
    return this.http.put(endpoint + 'stock_journals/nlv/activate/' + id, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getArchivedNlv() : Observable<any> {
    return this.http.get(endpoint + 'stock_journals/nlv_archived/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  closeStockUnit(value) : Observable<any> {
    return this.http.post(endpoint + 'stock_journals/stock_close', value, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getSymbolStyleSession(symbol): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/symbol_style_session/get', {symbol : symbol}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  postSymbolStyleSession(symbol, instrumentId, styleId): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/symbol_style_session/post', {symbol : symbol, instrument_id : instrumentId, style_id: styleId}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  calculateStatistic(value = null) : Observable<any> {
    return this.http.post(endpoint + 'stock_journals/stock_statistic', value, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getNlvValueCustom() : Observable<any> {
    return this.http.get(endpoint + 'stock_journals/nlv/custom', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getNlv() : Observable<any> {
    return this.http.get(endpoint + 'stock_journals/nlv/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  addTrader(trader) : Observable<any> {
    return this.http.post(endpoint + 'stock_journals', trader, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  editTrader(update, id) : Observable<any>{
    return this.http.put(endpoint + 'stock_journals/' + id, update, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  formValueQuickUpdate(id, update) : Observable<any>{
    console.log(update);
    return this.http.put(endpoint + 'stock_journals/quick_update/' + id, update, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  tradeStatusQuickUpdate(id, update) : Observable<any>{
    console.log(update);
    return this.http.put(endpoint + 'stock_journals/status_update/' + id, update, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockLogs() : Observable<any>{
    return this.http.get(endpoint + 'stock_journals/stock_logs/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockLogsGroupWithSymbol() : Observable<any>{
    return this.http.get(endpoint + 'stock_journals/stock_logs/symbol_groups', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  filterStockLogsGroupWithSymbol(value) : Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_logs/symbol_groups', value, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockLogsGroupByStockJournalId(stock_journal_id) : Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_logs/stock_journal_id', {stock_journal_id : stock_journal_id}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockTradeJournalText(stock_journal_id) : Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_journal_text/all', {stock_journal_id : stock_journal_id}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  } 

  postStockTradeJournalText(value) : Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_journal_text',value, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  } 

  getTradersBySymbol(symbol, instrumentId) : Observable<any>{
    return this.http.post(endpoint + 'stock_journals/symbol', {symbol : symbol, instrumentId : instrumentId}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getMatpValueBySymbol(symbol) : Observable<any>{
    return this.http.post(endpoint + 'stock_journals/symbol/matp/get', {symbol : symbol}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  } 

  createOrUpdateMatpValue(symbol, matp, mbp, target_price_rating_text) : Observable<any>{
    return this.http.post(endpoint + 'stock_journals/symbol/matp', {symbol : symbol, matp : matp, mbp : mbp, target_price_rating_text : target_price_rating_text}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  } 

  getPreviousEntryJournalBySymbol(symbol, instrumentId, styleId) : Observable<any>{
    return this.http.post(endpoint + 'stock_journals/previous_journals', {symbol : symbol, instrumentId : instrumentId, styleId : styleId}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  } 

  getTraderObjectById(id) : Observable<any>{
    return this.http.get(endpoint + 'stock_journals/' + id, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  } 

  deleteTraderObjectById(id) : Observable<any>{
    return this.http.delete(endpoint + 'stock_journals/' + id, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  } 

  getSymbols(searchString): Observable<any>{
    return this.http.post(stockEndpoint + 'apiStockAutoComplete', {search : searchString}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getCurrentPriceBySymbol(symbol): Observable<any>{
    return this.http.post(stockEndpoint + 'apiGetStockCurrentPrice', {stock : symbol}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockHighlightBySymbol(symbol): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_highlight', {symbol : symbol}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getDirections(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/directions/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStyles(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/styles/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getInstruments(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/instruments/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getNlvAccountTypes(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/nlv_account_types/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getEntryPoints(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/entry_points/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getExitAtLosses(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/exit_at_losses/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getExitAtProfits(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/exit_at_profits/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStrategies(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/strategies/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockChallengesUser(stock_journal_id): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_challenges/user', {stock_journal_id : stock_journal_id}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockRulesUser(stock_journal_id): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_rules/user', {stock_journal_id : stock_journal_id}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockChallenges(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/stock_challenge_types/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getStockRules(): Observable<any>{
    return this.http.get(endpoint + 'stock_journals/stock_rule_types/all', httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  postStockChallenges(value): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_challenge_types', value, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  postStockRules(value): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/stock_rule_types', value, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  getOverallExecution(stock_journal_id): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/overall_executions/get', {stock_journal_id : stock_journal_id}, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

  postOverallExecution(value): Observable<any>{
    return this.http.post(endpoint + 'stock_journals/overall_executions', value, httpOptions).pipe(
      catchError(this.requestHandler.handleHttpErrors)
    );
  }

}
