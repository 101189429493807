<mat-spinner *ngIf="loadingBar" style="margin-top:10%;margin-left:42%;"></mat-spinner>


<div *ngIf="!isActive">
  <h5 class="">
    <span>This reflection is not ready yet.</span>
  </h5>
</div>

<div *ngIf="!isMTB&&!loadingBar&&isActive">
  <h5 class="">
    <span>Kindly note that this reflection is only meant for MTB members,
      if you have received this in error, contact the Beyond Insights team.</span>
  </h5>
</div>
<section [ngClass]="{'container':!!CoachComments}" *ngIf="!loadingBar&&isMTB&&isActive" style="padding:0;">
  <div>
    <h3 class="blueheader" style="padding-bottom:10px;">
      <span>{{pagedesc}}</span>
    </h3>
    <!--<div>
      <button class="back-btn" (click)="onBack()" style="padding-top:0;padding-left:20px;"> < B A C K </button>
    </div>-->
  </div>  

  <mat-card [ngClass]="{'grid1':!!CoachComments}">  
    <mat-card-header class="center-content" *ngIf="questions[0].type!='TITLE'" style="padding-top:20px;">    
      <mat-card-title [innerHTML]="ReflectionTitle">
      </mat-card-title>
    </mat-card-header>
    
      <mat-card-content class="center-content" [ngClass]="{'paddingtopnone': questions[0].type=='TITLE'}">        
          <div>            
            <div *ngFor="let qs of questionsGroup; let i = index" style="padding:20px;padding-top:10px;" >
                <mat-divider [inset]="true" *ngIf="qs.drawline==true" style="padding-bottom: 1.4em;"></mat-divider>
                <h4 *ngIf="qs.type=='TITLE'&&!qs.normalfont" style="font-size: 20px;font-weight:500;margin-bottom:2px;line-height:30px;margin-left:10%;margin-right:10%;"
                [innerHTML]="qs.question"></h4>
                <div *ngIf="qs.type=='TITLE'&&qs.normalfont" style="margin-bottom:2px;margin-left:10%;margin-right:10%;" [innerHTML]="qs.question">                    
                </div>
                <div class="tablestyle">
                  <table mat-table [dataSource]="qs.questions" class="mat-elevation-z8" (matSortChange)="sortData(i,$event)" matSort  matSortActive="result" matSortDirection="desc" style="width:80%;" matSortDisableClear>
                      <ng-container matColumnDef="task">
                          <th mat-header-cell *matHeaderCellDef > {{processHeader(i,0)}} </th>                          
                          <td mat-cell *matCellDef="let element"> {{element.question}} </td>
                      </ng-container>
                      
                      <!-- Name Column -->
                      <ng-container matColumnDef="result">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{processHeader(i,1)}} </th>                        
                        <td mat-cell *matCellDef="let element"> {{element.feedback.value}}</td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
            </div>              
            
          </div>                  
      </mat-card-content>  
  </mat-card>

  <mat-card class="grid2" *ngIf="!!CoachComments">
    <mat-card-header class="center-content" style="padding-top:20px;">    
      <mat-card-title>{{CoachCommentsTitle}}
      </mat-card-title>
    </mat-card-header>  
    <mat-card-content>
      <div *ngFor="let qs of questions" >
        <div style="padding:10px;" *ngIf="qs.nocomment!=true">
          <div style="font-size:0.7em;">
            {{qs.question}}
          </div> 
          <div>
            {{(qs.feedback.coach_comment==null)?"Pending":qs.feedback.coach_comment}}
          </div>          
          <div class="coachcommentdate">
            {{dateProcess(qs.feedback.coach_updated_at)}}
          </div>          
        </div>
        <mat-divider [inset]="true"></mat-divider>
      </div>      
    </mat-card-content>
  </mat-card>
</section>