import { Component, OnInit,Input } from '@angular/core';
import { SingleDataSet, Label } from 'ng2-charts';
import { NgForm,FormControl, FormGroup, Validators,FormGroupDirective } from '@angular/forms'
import { ChartType } from 'chart.js';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { GlobalVariableService } from '../../services/global-variable.service';
import { StorageService } from '../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {ErrorStateMatcher} from '@angular/material/core';
import {lifeWheelColors} from '../../shared/event-constants';

export class InstantErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null,
               form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-life-wheel-reflection-page',
  templateUrl: './life-wheel-reflection-page.component.html',
  styleUrls: ['./life-wheel-reflection-page.component.scss']
})
export class LifeWheelReflectionPageComponent implements OnInit {

  @Input()
  PageDescription: string = "";

  @Input()
  RouteBack: string = "/qcard/reflection1";

  @Input()
  RouteSubmit: string = "/qcard/reflection1";

  @Input()
  SubmitText: string = "Next";

  @Input()
  LifeWheelTitle1: string = "";

  @Input()
  LifeWheelTitle2: string = "";

  @Input()
  LifeWheelHeader: string = "";

  @Input()
  LifeWheelTab: number = 0;

  public lifeWheelTabIndex = 0;
  public polarAreaChartLabels: Label[] = ['Work/Career/Mission','Financial','Hobby/Passion/Fun','Relationship','Health','Growth/Personal Development','Contribution','Spiritual/Finding Meaning'];
  public polarAreaChartData: SingleDataSet = [0,0,0,0,0,0,0,0];
  public polarAreaLegend = true;
  public polarAreaChartType: ChartType = 'polarArea';
  public rangeValue=1;

  private questions:any;
  private lifestagetradingdata:any;
  private questions1list:any;
  private questions2list:any;
  lwcolors:any;

  matcher = new InstantErrorStateMatcher();

  loadingBar: boolean = true;
  isMTB: boolean = false;
  isActive: boolean = true;

  LifeWheelValueForm = new FormGroup({
    lifewheeldropdown1: new FormControl('', Validators.required),
    lifewheeldropdown2: new FormControl('', Validators.required),
  });

  LifeWheelSliders:any;
  lifewheel_validation_messages = {}

  validation_messages = {
    'lifewheeldropdown1': [
      { type: 'required', message: 'Life Wheel 1 is required' }
    ],
    'lifewheeldropdown2': [
      { type: 'required', message: 'Life Wheel 2 is required' }
    ]
  }

  private options = {
    scale: {
      ticks: {
          beginAtZero: true,
          // stepSize: 1,
          min: 0,
          max: 10,
      },
    },
  };

  public polarAreaChartColors: Array<any> = [
    {
      backgroundColor: [
        '#2e67bf',
        '#7f8fa4'
      ],
    }
  ];


  constructor(
    private apiService: ApiService,
    private globalVariableService: GlobalVariableService,
    private storageService: StorageService,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public route: Router
  ) { }

  ngOnInit(): void {
    this.rangeValue = 1;
    this.lifeWheelTabIndex = this.LifeWheelTab;
    this.lwcolors = lifeWheelColors;
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);

  }

  updatePolarValue(formitem,e)
  {
    this.LifeWheelSliders.get(formitem).setValue(e);
    console.log(this.LifeWheelSliders.get(formitem).value);
    this.LifeWheelSliders.get(formitem).markAsDirty();
  }

  processData() {
    let i;
    if (this.lifestagetradingdata.length>1)
    {
      let group={}
      for (i=0;i<this.lifestagetradingdata[0].questions.length;i++)
      {
        group['mtbquestion'+this.lifestagetradingdata[0].questions[i].id] = new FormControl('',[Validators.min(0)]);
        this.polarAreaChartLabels[i] = this.lifestagetradingdata[0].questions[i].question;
        if (this.lifestagetradingdata[0].questions[i].feedback.value!=null)
          this.polarAreaChartData[i] = +this.lifestagetradingdata[0].questions[i].feedback.value;

      }

      this.LifeWheelSliders = new FormGroup(group);
      console.log(this.LifeWheelSliders);

      for (i=0;i<this.lifestagetradingdata[0].questions.length;i++)
      {
        if (this.lifestagetradingdata[0].questions[i].feedback.value!=null)
          this.LifeWheelSliders.get('mtbquestion'+this.lifestagetradingdata[0].questions[i].id).setValue(this.lifestagetradingdata[0].questions[i].feedback.value);
        else
          this.LifeWheelSliders.get('mtbquestion'+this.lifestagetradingdata[0].questions[i].id).setValue(0);
      }

      console.log(this.LifeWheelSliders);



      this.questions1list = { ...this.lifestagetradingdata[1].questions[0] };
      this.questions2list = { ...this.lifestagetradingdata[1].questions[1] };

      if (this.questions1list.feedback.value != null)
        this.LifeWheelValueForm.controls.lifewheeldropdown1.setValue(this.questions1list.feedback.selection_id);

      if (this.questions2list.feedback.value != null)
        this.LifeWheelValueForm.controls.lifewheeldropdown2.setValue(this.questions2list.feedback.selection_id);

    }
    else
      this.isActive = false;

  }

  ngAfterViewInit() {
    this.apiService.getReflections().subscribe(data => {
      //if (!this.authService.authCheckApi(data)) return;
      console.log(data.result);

      if (data.code === 0) {
        this.isMTB = true;
        // Filtered based on data for lifewheel part 1
        this.lifestagetradingdata = data.result.filter(x=> x.description===this.PageDescription);
        console.log(this.lifestagetradingdata);
        this.processData();

      }
      else {
        this.isMTB = false;
      }
      this.loadingBar = false;

    });
  }

  onResult() {
    let dataparam = Array();
    let i=0;
    for (i=0;i<this.lifestagetradingdata[0].questions.length;i++)
    {
      let qparam;
      qparam = {
        question_id:this.lifestagetradingdata[0].questions[i].id,
        value: this.polarAreaChartData[i]
      }
      dataparam.push(qparam);
    }

    let param =
    {
      page_id:this.lifestagetradingdata[0].id,
      feedbacks:dataparam

    }

    this.apiService.updateFeedback(param).subscribe(data => {
      if (data.code === 0) {
        // If there are requested fields

        Swal.fire({
            icon: 'success',
            title: this.lifestagetradingdata[0].name + ' points updated',
            showConfirmButton: false,
            timer: 1500,
            heightAuto: false
        });
        this.lifeWheelTabIndex = 1;

      }
      else {
        var errorMessages = '';

        // If there are errors
        if (!!data.errors) {
            // Get array of error fields
            var arrayOfFields = Object.keys(data.errors);
            // Loop the array of error fields
            for (var i = 0; i < arrayOfFields.length; i++) {
                // Get the array of messages for each field
                var arrayOfMessages = data.errors[arrayOfFields[i]];
                for (var j = 0; j < arrayOfMessages.length; j++) {
                    errorMessages += arrayOfMessages[j] + '\n';
                }
            }
        }

        Swal.fire({
            title: 'Error, kindly inform administrator',
            text: errorMessages,
            icon: 'warning',
            confirmButtonText: 'Okay',
            heightAuto: false
        });
      }
    });
    console.log(dataparam);
  }

  onResultBack() {
    this.lifeWheelTabIndex = 0;
  }

  onBack() {
    this.route.navigateByUrl(this.RouteBack);
  }

  changeValueOne(e) {
    console.log("Changing value");
    let val = this.questions1list.selection.filter(x=>x.id==e)
    this.questions2list = { ...this.lifestagetradingdata[1].questions[1] };
    this.questions2list.selection = this.questions2list.selection.filter(x=>x.value!=val[0].value);
    console.log(this.questions2list);
    this.LifeWheelValueForm.controls.lifewheeldropdown2.reset();

  }

  onSubmit() {
    console.log("calling submit");
    let dataparam = Array();
    let qparam;
    let vfilter;
    let param;

    vfilter = this.questions1list.selection.filter(x => x.id == this.LifeWheelValueForm.controls.lifewheeldropdown1.value)[0].value;

    qparam = {
      question_id: this.questions1list.id,
      selection_id: this.LifeWheelValueForm.controls.lifewheeldropdown1.value,
      value: vfilter
    }

    dataparam.push(qparam);

    vfilter = this.questions2list.selection.filter(x => x.id == this.LifeWheelValueForm.controls.lifewheeldropdown2.value)[0].value;

    qparam = {
      question_id: this.questions2list.id,
      selection_id: this.LifeWheelValueForm.controls.lifewheeldropdown2.value,
      value: vfilter
    }

    dataparam.push(qparam);

    param =
    {
      page_id: this.lifestagetradingdata[1].id,
      feedbacks: dataparam

    }

    console.log(param);

    this.apiService.updateFeedback(param).subscribe(data => {
      if (data.code === 0) {
        // If there are requested fields

        Swal.fire({
          icon: 'success',
          title: this.lifestagetradingdata[1].name + ' updated',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false
        });

        this.route.navigateByUrl(this.RouteSubmit);
      }
      else {
        var errorMessages = '';

        // If there are errors
        if (!!data.errors) {
          // Get array of error fields
          var arrayOfFields = Object.keys(data.errors);
          // Loop the array of error fields
          for (var i = 0; i < arrayOfFields.length; i++) {
            // Get the array of messages for each field
            var arrayOfMessages = data.errors[arrayOfFields[i]];
            for (var j = 0; j < arrayOfMessages.length; j++) {
              errorMessages += arrayOfMessages[j] + '\n';
            }
          }
        }

        Swal.fire({
          title: 'Error, kindly inform administrator',
          text: errorMessages,
          icon: 'warning',
          confirmButtonText: 'Okay',
          heightAuto: false
        });
      }
    });
  }
}
