import { Component, OnInit,Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-single-reflection-surveymonkey',
  templateUrl: './single-reflection-surveymonkey.component.html',
  styleUrls: ['./single-reflection-surveymonkey.component.scss']
})
export class SingleReflectionSurveymonkeyComponent implements OnInit {


  @Input()
  PageID: number = -1;

  @Input()
  ReflectionTitle: string = "";

  @Input()
  SubmitText: string = "Submit";

  @Input()
  RouteBack: string = "/reflection/reflection1";

  @Input()
  RouteSubmit: string = "/qcard/mtbtradingstyle2";

  @Input()
  FilterObjects: any;

  @Input()
  UniqueCombo: boolean = false;

  @Input()
  CoachComments: boolean = false;

  @Input()
  CoachCommentsTitle: string = "Facilitators Comment";

  @Input()
  LineAfterQuestion: any = [];

  private questions: any;
  private mbtradingdata: any;
  private pagedesc:string="";
  private student_id:string="";

  loadingBar: boolean = true;
  isMTB: boolean = false;
  isActive:boolean = true;
  isSubmit: boolean = true;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  processData() {

    if (this.mbtradingdata.length>0)
    {
      this.pagedesc = this.mbtradingdata[0].description;
      this.student_id = this.mbtradingdata[0].student_id;
    }

    if (this.pagedesc.includes("[st_id_value]"))
    {
      this.pagedesc = this.pagedesc.replace("[st_id_value]",this.student_id);
      console.log(this.pagedesc);
    }
    window.location.replace(this.pagedesc);
  };

  ngAfterViewInit() {
    this.apiService.getReflections().subscribe(data => {
      //if (!this.authService.authCheckApi(data)) return;
      if (data.code === 0) {
        this.isMTB = true;
        this.mbtradingdata = data.result.filter(x => x.id == this.PageID);
        console.log(this.mbtradingdata);
        this.processData();
      }
      else
      {
        this.isMTB = false;
        this.loadingBar = false;
      }

    });
  }


}
