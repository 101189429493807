<mat-spinner *ngIf="loadingBar" style="margin-top:10%;margin-left:42%;"></mat-spinner>

<div *ngIf="!isActive">
  <h5 class="">
    <span>This reflection is not ready yet.</span>
  </h5>
</div>

<div *ngIf="!isMTB&&!loadingBar&&isActive">
  <h5 class="">
    <span>Kindly note that this reflection is only meant for MTB members,
      if you have received this in error, contact the Beyond Insights team.</span>
  </h5>
</div>
<section  *ngIf="!loadingBar&&isMTB&&isActive" style="padding:0;">
  <div>
    <h3 class="blueheader" style="padding-bottom:10px;">
      <span>{{pagedesc}}</span>
    </h3>
    <!--<div>
      <button class="back-btn" (click)="onBack()" style="padding-top:0;padding-left:20px;"> < B A C K </button>
    </div>-->
  </div>

<div class="container-fluid">
  <div class="row">
  <mat-card class="col" [ngClass]="{'col-lg-9':!!CoachComments}">
    <!--<mat-card-header class="center-content" *ngIf="questions[0].type!='TITLE'" style="padding-top:20px;">
      <mat-card-title [innerHTML]="ReflectionTitle">
      </mat-card-title>
    </mat-card-header>-->

      <mat-card-content class="center-content" [ngClass]="{'paddingtopnone': questions[0]?.type=='TITLE'}">
        <form [formGroup]="PrimaryTradingStyleForm" (ngSubmit)="onSubmit()">
          <div>
            <div *ngIf="PrimaryTradingStyleForm.errors?.uniquecombo && (PrimaryTradingStyleForm.touched || PrimaryTradingStyleForm.dirty)" class="cross-validation-error-message alert alert-danger">
              All ranks is required to be unique
            </div>
            <div *ngFor="let qs of questions" class="reflectionpadding">
              <mat-divider [inset]="true" *ngIf="qs.drawline==true" style="padding-bottom: 1.4em;"></mat-divider>
              <h4 *ngIf="qs.type=='TITLE'&&!qs.normalfont" style="font-size: 20px;font-weight:500;margin-bottom:2px;line-height:30px;padding-bottom: 0.5em;" [innerHTML]="qs.question"></h4>
              <div *ngIf="qs.type=='TITLE'&&qs.normalfont" style="padding-bottom: 0.5em;margin-bottom:2px;" [innerHTML]="qs.question">
              </div>
              <label *ngIf="(qs.visible==null||qs.visible!=false)&&qs.type!='TITLE'">{{qs.question}}</label>
              <mat-form-field appearance="outline" class="reflectionform" *ngIf="(qs.visible==null||qs.visible!=false)&&qs.type!='TITLE'" color="primary" [matTooltip]="qs.description" matTooltipPosition="above" matTooltipClass="tooltip-slider" >
                <!--<mat-label>{{qs.question}}
                </mat-label>-->
                <mat-select matNativeControl required formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='SELECT'" (change)="onChange($event.target.value)" [readonly]="qs.readonly">
                  <mat-option *ngFor="let q of qs.selection" [value]=q.id>{{q.value}}</mat-option>
                </mat-select>
                <input matInput placeholder="" formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXT'" [readonly]="qs.readonly">
                <textarea rows="4" matInput placeholder=""
                formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXTAREA'" [readonly]="qs.readonly"></textarea>
                <input type="number" formControlName="{{'mtbquestion'+qs.id}}" matInput required *ngIf="qs.type=='RANGE'" [errorStateMatcher]="matcher" hidden>
                <mat-error *ngFor="let validation of validation_messages['mtbquestion'+qs.id]">
                  <span class="error-message" *ngIf="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).hasError(validation.type) &&
                            (PrimaryTradingStyleForm.get('mtbquestion'+qs.id).dirty ||
                            PrimaryTradingStyleForm.get('mtbquestion'+qs.id).touched)">
                    {{validation.message}}
                  </span>
                </mat-error>
                <mat-slider thumbLabel [step]="qs.range.step" [tickInterval]="qs.range.step" [min]="qs.range.min" [max]="qs.range.max" [value]="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).value" (change)="updatePolarValue('mtbquestion'+qs.id, $event.value)" *ngIf="qs.type=='RANGE'" [readonly]="qs.readonly"></mat-slider>
              </mat-form-field>

            </div>

          </div>
          <div>
            <button mat-raised-button color="primary" class="submit-button" *ngIf="SubmitText!=''"
              [disabled]="!PrimaryTradingStyleForm.valid||PrimaryTradingStyleForm.errors?.uniquecombo||!isSubmit">{{SubmitText}}</button>
          </div>
        </form>
      </mat-card-content>
  </mat-card>

  <mat-card class="col" *ngIf="!!CoachComments">
    <mat-card-header class="center-content" style="padding-top:20px;" >
      <mat-card-title class="tw-p-4">{{CoachCommentsTitle}}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngFor="let qs of questions" style="padding-bottom:10px;">
        <div style="padding:10px;" *ngIf="qs.nocomment!=true">
          <div style="font-size:0.7em;">
            {{qs.question}}
          </div>
          <div>
            {{(qs.feedback.coach_comment==null)?"Pending":qs.feedback.coach_comment}}
          </div>
          <div class="coachcommentdate">
            {{dateProcess(qs.feedback.coach_updated_at| dateNormal | date:'EEEE, MMMM d, y')}}
          </div>
        </div>
        <mat-divider [inset]="true" *ngIf="qs.nocomment!=true"></mat-divider>
      </div>
    </mat-card-content>
  </mat-card>
  </div>
</div>
</section>
