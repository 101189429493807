import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import * as CustomEditor from 'assets/ckeditor.js'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

@Component({
  selector: 'app-sp-rich-text-editor',
  templateUrl: './sp-rich-text-editor.component.html',
  styleUrls: ['./sp-rich-text-editor.component.scss']
})

export class SpRichTextEditorComponent implements AfterViewInit {
    // Object holding the config for ckeditor
    @Input() ckEditorConfig: object = {};  
    // Flag to determine editable
    @Input() isDisabled: boolean = true;  
    // The html string for the ckeditor data
    @Input() loadEditorData: string = ''; 

    // Emits the editor data to be passed to parent
    @Output() editorDataUpdated = new EventEmitter<string>();  

    // Declaring editor
    public ckEditor = CustomEditor;    

  // Getting the editor data and assigning it to variable
  getEditorData({ editor }: ChangeEvent) {    
    this.editorDataUpdated.emit(editor.getData().toString());	    
  }
  
  constructor() { }

  ngAfterViewInit(): void {        
  }

}
