import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariableService {

  constructor() { }

  setGlobalVariable(variable:any, fieldName:string) {    
    this[fieldName] = variable;
  }

  getGlobalVariable(fieldName: string) {
    return this[fieldName];
  }
}
