<mat-spinner *ngIf="loadingBar" style="margin-top:10%;margin-left:42%;"></mat-spinner>

<div *ngIf="!isMTB&&!loadingBar&&isActive">
  <h5 class="">
      <span>Kindly note that this reflection is only meant for MTB members, if you have received this in error, contact the Beyond Insights team.</span>
  </h5>
</div>

<div *ngIf="!isActive">
  <h5 class="">
    <span>This reflection is not ready yet.</span>
  </h5>
</div>

<mat-card *ngIf="!loadingBar&&isMTB&&isActive">
  <mat-card-header>
      <mat-card-title></mat-card-title>
    </mat-card-header>
  <mat-card-content>

  <mat-tab-group animationDuration="0ms" [(selectedIndex)]="lifeWheelTabIndex" dynamicHeight>
    <mat-tab label="Life Wheel Action" disabled="true">
      <div class="padding:20px;">
          <div class="tw-text-lg tw-py-8">
              <span [innerHtml]="LifeWheelTitle1"></span>
          </div>
          <form [formGroup]="LifeWheelSliders">
            <mat-form-field class="reflectionform" *ngFor="let item of this.lifestagetradingdata[0].questions; index as i"  appearance="outline" [matTooltip]="item.description" matTooltipPosition="above" matTooltipClass="tooltip-slider">
              <mat-label>{{item.question}} : {{polarAreaChartData[i]}} <mat-icon  style="display:inline-flex;vertical-align:top;">info</mat-icon></mat-label>
              <mat-slider thumbLabel [tickInterval]="item.range.step" [min]="item.range.min" [max]="item.range.max" [value]="LifeWheelSliders.get('mtbquestion'+item.id).value" (change)="updatePolarValue('mtbquestion'+item.id, $event.value)" [(ngModel)]="polarAreaChartData[i]" [ngModelOptions]="{standalone: true}"></mat-slider>
              <input formControlName="{{'mtbquestion'+item.id}}" placeholder="Just a placeholder" [errorStateMatcher]="matcher" matInput hidden>
              <mat-error>
                <span class="error-message">
                  {{item.question}} must be more than 0
                </span>
              </mat-error>
            </mat-form-field>
          </form>

        <div>
          <button mat-raised-button class="back-button" (click)="onBack()">Back</button>
          &nbsp;
          <button mat-raised-button color="primary" class="submit-button" (click)="onResult()" [disabled]="!LifeWheelSliders.valid">Next</button>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="Life Wheel Result" disabled="true">
      <div class="row">
        <div class="col">
          <div class="tw-text-lg tw-py-4">
                <span [innerHtml]="LifeWheelHeader"></span>
          </div>
          <div>
            <div style="display: block;" class="tw-p-2">
              <canvas baseChart
                [data]="polarAreaChartData"
                [labels]="polarAreaChartLabels"
                [legend]="polarAreaLegend"
                [chartType]="polarAreaChartType"
                [options]="options"
                [colors]="lwcolors"
                >
              </canvas>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="tw-text-lg tw-pt-4 tw-pb-8">
                <span [innerHtml]="LifeWheelTitle2"></span>
          </div>
          <form [formGroup]="LifeWheelValueForm" (ngSubmit)="onSubmit()">
                <mat-form-field class="reflectionform" appearance="outline">
                    <mat-label>{{questions1list.question}}</mat-label>
                    <select matNativeControl required formControlName="lifewheeldropdown1" (change)="changeValueOne($event.target.value)">
                      <option *ngFor="let q of questions1list.selection" [value]=q.id>{{q.value}}</option>
                    </select>
                    <mat-error *ngFor="let validation of validation_messages.lifewheeldropdown1">
                      <span class="error-message" *ngIf="LifeWheelValueForm.get('lifewheeldropdown1').hasError(validation.type) &&
                                  (LifeWheelValueForm.get('lifewheeldropdown1').dirty ||
                                  LifeWheelValueForm.get('lifewheeldropdown1').touched)">
                        {{validation.message}}
                      </span>
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="reflectionform" appearance="outline">
                      <mat-label>{{questions2list.question}}</mat-label>
                      <select matNativeControl required formControlName="lifewheeldropdown2">
                        <option>&nbsp;</option>
                        <option *ngFor="let q of questions2list.selection" [value]=q.id>{{q.value}}</option>
                      </select>
                      <mat-error *ngFor="let validation of validation_messages.lifewheeldropdown2">
                        <span class="error-message" *ngIf="LifeWheelValueForm.get('lifewheeldropdown2').hasError(validation.type) &&
                                    (LifeWheelValueForm.get('lifewheeldropdown2').dirty ||
                                    LifeWheelValueForm.get('lifewheeldropdown2').touched)">
                          {{validation.message}}
                        </span>
                      </mat-error>
                    </mat-form-field>
            <div>
              <button mat-raised-button type='button' class="back-button" (click)="onResultBack()">Back</button>
              &nbsp;
              <button mat-raised-button color="primary" class="submit-button">{{SubmitText}}</button>
            </div>
          </form>
        </div>

      </div>

    </mat-tab>
  </mat-tab-group>
</mat-card-content>
</mat-card>

