import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, retry } from 'rxjs/operators';

import { environment } from '$environment';

import { RequestHandlersService } from './request-handlers.service';

export type ResourceType = 'dashboard';

@Injectable({
  providedIn: 'root',
})
export class BusinessIntelligenceService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly requestHandlersService: RequestHandlersService,
  ) {}

  fetchGuestToken(
    resourceType: ResourceType,
    resourceId: string,
    isApplyStudentIdFilter: boolean,
  ) {
    return this.httpClient
      .post<any>(`${environment.nestApiUrl}business-intelligence/guest-token`, {
        resourceType,
        resourceId,
        isApplyStudentIdFilter,
      })
      .pipe(retry(3), catchError(this.requestHandlersService.handleHttpErrors));
  }
}
