import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-informational-popup',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './informational-popup.component.html',
  styleUrls: ['./informational-popup.component.scss'],
})
export class InformationalPopupComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @Input()
  title = '';

  @Input()
  html = '';

  @Input()
  confirmButtonText = 'CLOSE';

  @Input()
  isOnlyClosableAfterScrollToBottomOfBody = false;

  @ViewChild('body')
  private bodyElement: ElementRef<HTMLDivElement>;

  readonly scrollThreshold = 5;

  isBodyScrollable = false;

  canScrollBodyUp = false;

  canScrollBodyDown = true;

  private bodyScrollEventListener: () => void;

  safeHtml: SafeHtml;

  progressBarValue = 0;

  canClose = true;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domSanitizer: DomSanitizer,
    private readonly activeModal: NgbActiveModal,
    private readonly renderer2: Renderer2,
  ) {}

  ngOnInit() {
    this.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.html);
  }

  ngAfterViewInit() {
    const bodyNativeElement = this.bodyElement.nativeElement;

    if (bodyNativeElement.scrollHeight > bodyNativeElement.clientHeight) {
      this.isBodyScrollable = true;

      if (this.isOnlyClosableAfterScrollToBottomOfBody) {
        this.canClose = false;
      }

      this.changeDetectorRef.detectChanges();

      this.bodyScrollEventListener = this.renderer2.listen(
        bodyNativeElement,
        'scroll',
        (event: Event) => {
          const element = event.target as HTMLElement;

          if (element) {
            this.canScrollBodyUp = element.scrollTop > 0;
            this.canScrollBodyDown =
              element.scrollTop + element.clientHeight <
              element.scrollHeight - this.scrollThreshold;
            this.progressBarValue =
              (element.scrollTop /
                (element.scrollHeight - element.clientHeight)) *
              100;

            if (!this.canClose) {
              if (
                element.scrollTop + element.clientHeight >=
                element.scrollHeight - this.scrollThreshold
              ) {
                this.canClose = true;
              }
            }

            this.changeDetectorRef.markForCheck();
          }
        },
      );
    }
  }

  ngOnDestroy() {
    if (this.bodyScrollEventListener) {
      this.bodyScrollEventListener();
    }
  }

  onScrollBodyUp() {
    const bodyNativeElement = this.bodyElement.nativeElement;

    bodyNativeElement.scrollTop -= bodyNativeElement.clientHeight;
  }

  onScrollBodyDown() {
    const bodyNativeElement = this.bodyElement.nativeElement;

    bodyNativeElement.scrollTop += bodyNativeElement.clientHeight;
  }

  onClose() {
    this.activeModal.close();
  }
}
