<mat-spinner *ngIf="loadingBar" style="margin-top:10%;margin-left:42%;"></mat-spinner>

<div *ngIf="!isActive">
  <h5 class="">
    <span>This reflection is not ready yet.</span>
  </h5>
</div>

<mat-card *ngIf="!loadingBar&&isMTB&&isActive">
  <mat-card-header class="headerpadding center-content" style="width:100%;">    
    <mat-card-title>Your Life Wheel</mat-card-title>
  </mat-card-header>
    <mat-card-content style="display:flex">    
        <div class="containerchart" *ngFor="let lw of lifewheeldata">
            <canvas baseChart
              [data]="lw.points"
              [labels]="polarAreaChartLabels"
              [legend]="polarAreaLegend"
              [chartType]="polarAreaChartType"
              [options]="options"
              [colors]="lwcolors"
              >
            </canvas>
            <br>
            <div style="display:flex;justify-content:center">
              {{lw.description}}
            </div>
        </div>
    </mat-card-content> 
</mat-card>

<div *ngIf="!isMTB&&!loadingBar&&isActive">
  <h5 class="">
    <span>Kindly note that this reflection is only meant for MTB members,
      if you have received this in error, contact the Beyond Insights team.</span>
  </h5>
</div>
<mat-card *ngIf="!loadingBar&&isMTB&&isActive">  
  <mat-card-content class="center-content">
    <button mat-raised-button class="back-button" (click)="onBack()">Back</button>
  </mat-card-content>
  <mat-card-header class="center-content headerpadding">    
    <mat-card-title [innerHTML]="ReflectionTitle">
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="center-content">
    <form [formGroup]="PrimaryTradingStyleForm" (ngSubmit)="onSubmit()">
      <div>
        <div *ngFor="let qs of questions" >
          <mat-form-field class="reflectionform" appearance="outline" *ngIf="qs.visible==null||qs.visible!=false" [matTooltip]="qs.description" matTooltipPosition="after" matTooltipClass="tooltip-slider" >
            <mat-label>{{qs.question}}</mat-label>
            <mat-select matNativeControl required formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='SELECT'" (change)="onChange($event.target.value)">
              <mat-option *ngFor="let q of qs.selection" [value]=q.id>{{q.value}}</mat-option>
            </mat-select>            
            <input matInput placeholder="" formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXT'">
            <textarea rows="4" matInput placeholder=""
            formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXTAREA'"></textarea>
            <input type="number" formControlName="{{'mtbquestion'+qs.id}}" matInput required *ngIf="qs.type=='RANGE'" hidden>
            <mat-error *ngFor="let validation of validation_messages['mtbquestion'+qs.id]">
              <span class="error-message" *ngIf="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).hasError(validation.type) && 
                        (PrimaryTradingStyleForm.get('mtbquestion'+qs.id).dirty || 
                        PrimaryTradingStyleForm.get('mtbquestion'+qs.id).touched)">
                {{validation.message}}
              </span>
            </mat-error>
            <mat-slider thumbLabel [tickInterval]="qs.range.step" [min]="qs.range.min" [max]="qs.range.max" [value]="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).value" (change)="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).setValue($event.value)" *ngIf="qs.type=='RANGE'"></mat-slider>
          </mat-form-field>
          
        </div>
        
      </div>
      <div>
        <button mat-raised-button color="primary" class="submit-button"
          [disabled]="!PrimaryTradingStyleForm.valid">{{SubmitText}}</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>