import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {TraderService} from '../../services/trader.service';


@Component({
  selector: 'app-matp-popup',
  templateUrl: './matp-popup.component.html',
  styleUrls: ['./matp-popup.component.scss']
})
export class MatpPopupComponent implements OnInit {

  @Input() symbol="";
  @Input() title="Guide";

  calcMatp:number|null = null;
  matpText:string = "";
  matpIndex:number = 2;
  currencySymbol:string = "$";
  seperatorSymbol:string = ",";
  arrowSymbol:string = "➝";
  lastEarningDate:string = "";
  lastUpdatedDate:string = "";

  @ViewChild('mbSearch') searchElement: ElementRef;


  constructor(public activeModal: NgbActiveModal, private traderService : TraderService) { }

  ngOnInit(): void {
    console.log("showing symbol: " + this.symbol);
    this.getMatpValues();
  }

  getMatpValues()
  {
    this.traderService.getMatpValueBySymbol(this.symbol).subscribe(
      output => {

        if(output.result.record)
        {
          this.calcMatp = output.result.record.matp;
          this.matpText = output.result.record.target_price_rating_text;
          this.lastUpdatedDate = output.result.record.updated_at;
        }

        this.lastEarningDate = output.result.stockInformation.Highlights_Recent_Reported_Earnings_Date;

        console.log(output.result, "previous matp result");
      }
    )
  }

  clearForm() : void {
    this.matpText = "";
  }

  close():void {
    let matpValue = {"matp":-1,"mbp":-1,"target_price_rating_text":""};
    if (this.calcMatp!=null)
      matpValue = {"matp":this.calcMatp,"mbp":this.calcMatp/1.15, "target_price_rating_text":this.matpText}    
    this.activeModal.close(matpValue);
  }

  calculateMedian(data) {
    if (data.length==0) return null;
    const sorted = data.sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];

  }

  ngAfterViewInit():void {
    this.searchElement.nativeElement.focus();
  }

  calculatePosition(array) {
    let index = -1;
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes(this.currencySymbol)) {
        index = i;
        break;
      }
    }
    return index;
  }

  processValue(item) {
    let a:number|null = -1;
    if (item.includes(this.arrowSymbol))
    {
      let itemarray = item.split(this.arrowSymbol);
      item = itemarray[1];
    }

    a = isNaN(item.trim())?Number(item.trim().replace(this.currencySymbol,"").replace(this.seperatorSymbol,"")):null;
    return a;

  }

  matpChanged(arg) {
    let array=arg.split('\n').map(
      item => {
        let a = item.split('\t');
        let index = this.calculatePosition(a);
        if (index==-1) return null;
        let value = this.processValue(a[index]);
        return value;
      }
    ).filter(x => x!=null);


    this.calcMatp = this.calculateMedian(array);
  }

}
