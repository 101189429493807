
    <div class="video-player-wrapper">

      <h2>{{Title}}</h2>

      <vg-player style="width:70%;">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls>
          <vg-play-pause></vg-play-pause>
          <vg-playback-button [playbackValues]="[ '1.0', '1.5','2.0']"></vg-playback-button>

          <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

          <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

          <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
          <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

          <vg-track-selector></vg-track-selector>
          <vg-mute></vg-mute>
          <vg-volume></vg-volume>

          <vg-fullscreen></vg-fullscreen>
        </vg-controls>

        <video [vgMedia]="media" #media id="singleVideo" preload="auto" crossorigin>
          <source [src]="VideoURL" type="video/mp4">
        </video>
        <div class="watermark-vid">
          <div class="watermark-note">
            {{Name}}
          </div>
        </div>
      </vg-player>

    </div>
