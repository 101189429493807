import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tnc-popup',
  templateUrl: './tnc-popup.component.html',
  styleUrls: ['./tnc-popup.component.scss']
})
export class TncPopupComponent implements OnInit {

  @Input() url="";
  @Input() title="Guide";

  constructor(public activeModal: NgbActiveModal ) { }
  ngOnInit(): void {

    console.log("showing url: " + this.url);
  }

}
