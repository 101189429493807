import { Pipe, PipeTransform } from '@angular/core';
import {DategenService} from '../services/dategen.service'

@Pipe({
  name: 'dateNormal'
})
export class DateNormalPipe implements PipeTransform {

  constructor(
    private dategenService: DategenService
  ) { }

  transform(value:string): any {
    let v = this.dategenService.taskDate(value)
    return v;
  }

}
