<mat-spinner *ngIf="loadingBar" style="margin-top:10%;margin-left:42%;"></mat-spinner>

<!--<div *ngIf="!loadingBar&&isMTB&&isActive">
  <button class="back-btn" (click)="onBack()" style="padding-top:0;padding-left:20px;"> < B A C K </button>
</div>-->

<div *ngIf="!isActive">
  <h5 class="">
    <span>This reflection is not ready yet.</span>
  </h5>
</div>

<div *ngIf="!isMTB&&!loadingBar&&isActive">
  <h5 class="">
    <span>Kindly note that this reflection is only meant for MTB members,
      if you have received this in error, contact the Beyond Insights team.</span>
  </h5>
</div>
<mat-card *ngIf="!loadingBar&&isMTB&&isActive">  
  <mat-card-header class="center-content" style="padding-top:20px;">    
    <mat-card-title [innerHTML]="ReflectionTitle">
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="center-content">
    <form [formGroup]="PrimaryTradingStyleForm" (ngSubmit)="onSubmit()">
      <div>
        <div *ngFor="let qs of questions" >
          <mat-form-field class="reflectionform" appearance="outline" *ngIf="qs.visible==null||qs.visible!=false" color="primary" [ngClass]="{'tab_class': qs.isTab>0,'notab_class' : qs.isTab<=0}">
            <mat-label>{{qs.question}}</mat-label>
            <mat-select matNativeControl required formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='SELECT'" (change)="onChange($event.target.value)" readonly>
              <mat-option *ngFor="let q of qs.selection" [value]=q.id>{{q.value}}</mat-option>
            </mat-select>            
            <input matInput placeholder="" formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXT'">
            <textarea rows="4" matInput placeholder=""
            formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXTAREA'" readonly></textarea>
            <input type="number" formControlName="{{'mtbquestion'+qs.id}}" matInput required *ngIf="qs.type=='RANGE'" [errorStateMatcher]="matcher" hidden>
            <mat-error *ngFor="let validation of validation_messages['mtbquestion'+qs.id]">
              <span class="error-message" *ngIf="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).hasError(validation.type) && 
                        (PrimaryTradingStyleForm.get('mtbquestion'+qs.id).dirty || 
                        PrimaryTradingStyleForm.get('mtbquestion'+qs.id).touched)">
                {{validation.message}}
              </span>
            </mat-error>
            <section class="example-section">
              {{qs.range.min}}&nbsp;&nbsp;<mat-progress-bar class="example-margin" mode="determinate" [value]="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).value/qs.range.max*100" *ngIf="qs.type=='RANGE'"></mat-progress-bar>&nbsp;&nbsp;{{qs.range.max}}
              <div style="position: absolute; padding: 25px; color: white;"> {{PrimaryTradingStyleForm.get('mtbquestion'+qs.id).value}} </div>
            </section>
            <!--<mat-slider thumbLabel [tickInterval]="qs.range.step" [min]="qs.range.min" [max]="qs.range.max" [value]="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).value" (change)="updatePolarValue('mtbquestion'+qs.id, $event.value)" *ngIf="qs.type=='RANGE'"></mat-slider>-->
          </mat-form-field>
          
        </div>
        
      </div>
      <!--<div>
        <button mat-raised-button class="submit-button"
          [disabled]="!PrimaryTradingStyleForm.valid">{{SubmitText}}</button>
      </div>-->
    </form>
  </mat-card-content>
</mat-card>