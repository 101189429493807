
<div class="modal-header mat-header-layout" style="border-radius: 3px 3px 0px 0px !important;padding-left:10px;padding-right:10px;">
  <h4 class="modal-title" id="modal-basic-title">MATP Calculator</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <mat-icon style="font-size:30px;padding-right:30px;color: #fff;" aria-hidden="false"
      aria-label="Close">
      close
    </mat-icon>
  </button>
</div>
<div class="modal-body matpbody">
  <div class="row no-gutters" style="padding-bottom:0px;">
    <div class="col">
      Calculated <span *ngIf="calcMatp==null">MATP : None</span><span *ngIf="calcMatp!=null" style="color:#C6006F">MATP : ${{calcMatp| number:'1.2-2'}}</span>
    </div>
  </div>
  <div class="row no-gutters" style="padding-bottom:20px;">
    <div class="col">
      Calculated <span *ngIf="calcMatp==null"> MBP : None</span><span *ngIf="calcMatp!=null" style="color:#C6006F"> MBP : ${{calcMatp/1.15| number:'1.2-2'}}</span>
    </div>
  </div>
  <div class="row no-gutters" style="padding-bottom:20px;">
    <div class="col">
      <p>Last Earning Date : {{lastEarningDate}} <br> Last update date by user: {{lastUpdatedDate}}</p>
    </div>
  </div>
  <div class = "row">
    <div class = "col-9">
      <strong style="text-decoration: underline;">Instruction :</strong>
      <ol>
        <li>Please click the “Clear” button to clear the data in the textbox below if there is any data inside before you use it.</li>
        <li>Please copy the target price from Marketbeat analysts page and paste it here.</li>
      </ol>
    </div>
    <div class = "col-3">
      <button type="button" class="btn btn-danger" (click)="clearForm()" style="float:right;margin-top:60px;">Clear</button>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col">
      <div class="form-group">
        <textarea #mbSearch class="form-control" id="exampleFormControlTextarea1" rows="10"  onClick="this.select();" [(ngModel)]="matpText" (ngModelChange)="matpChanged($event)" ></textarea>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="close()">Done</button>
</div>
