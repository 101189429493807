import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { embedDashboard } from '@superset-ui/embedded-sdk';

import { environment } from '$environment';

import {
  BusinessIntelligenceService,
  ResourceType,
} from '../../services/business-intelligence.service';

@Component({
  selector: 'app-business-intelligence',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './business-intelligence.component.html',
  styleUrls: ['./business-intelligence.component.scss'],
})
export class BusinessIntelligenceComponent implements AfterViewInit, OnInit {
  @Input()
  resourceType: ResourceType = 'dashboard';

  @Input()
  resourceId: string;

  @Input()
  isApplyStudentIdFilter = true;

  resourceContainerId: string;

  isResourceAvailable = false;

  constructor(
    private readonly businessIntelligenceService: BusinessIntelligenceService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.resourceContainerId = `resource-container-${this.resourceId}`;
  }

  ngAfterViewInit() {
    embedDashboard({
      id: this.resourceId,
      supersetDomain: environment.supersetDomain,
      mountPoint: document.getElementById(this.resourceContainerId)!,
      fetchGuestToken: () => this.fetchGuestToken(),
      dashboardUiConfig: {
        hideTitle: true,
        hideChartControls: true,
      },
    }).then((embeddedDashboard) => {
      const iFrameElement = document.querySelector(
        `#${this.resourceContainerId} > iframe`,
      ) as HTMLIFrameElement;

      if (iFrameElement) {
        Object.entries({
          frameBorder: '0',
          height: '100%',
          scrolling: 'no',
          width: '100%',
        }).forEach(([attributeName, attributeValue]) => {
          iFrameElement.setAttribute(attributeName, attributeValue);
        });

        setInterval(async () => {
          const { height, width } = await embeddedDashboard.getScrollSize();

          iFrameElement.height = `${height}px`;
          iFrameElement.width = `${width}px`;
        }, 1000);

        this.isResourceAvailable = true;

        this.changeDetectorRef.markForCheck();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(uiEvent: UIEvent) {
    if (this.isResourceAvailable) {
      const iFrameElement = document.querySelector(
        `#${this.resourceContainerId} > iframe`,
      ) as HTMLIFrameElement;

      if (iFrameElement) {
        iFrameElement.height = `${document.body.clientHeight}px`;
        iFrameElement.width = `${document.body.clientWidth}px`;
      }
    }
  }

  protected fetchGuestToken() {
    return new Promise<string>((resolve, reject) => {
      this.businessIntelligenceService
        .fetchGuestToken(
          this.resourceType,
          this.resourceId,
          this.isApplyStudentIdFilter,
        )
        .subscribe(
          (value) => {
            resolve(value.guestToken);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
}
