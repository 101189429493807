<div class="modal-header mat-header-layout modal__header">
  <div class="modal-title modal__header__title unselectable-text">
    {{ title }}
  </div>

  <button
    class="close"
    [disabled]="!canClose"
    type="button"
    aria-label="Close"
    (click)="onClose()"
  >
    <mat-icon
      class="modal__header__icon"
      aria-hidden="false"
      aria-label="Close"
    >
      close
    </mat-icon>
  </button>
</div>

<mat-progress-bar
  *ngIf="isBodyScrollable"
  color="warn"
  mode="determinate"
  [value]="progressBarValue"
></mat-progress-bar>

<div
  #body
  class="modal-body modal__body"
  [ngClass]="{ 'modal__body--scrollable': isBodyScrollable }"
>
  <div
    class="modal__body__text unselectable-text"
    [innerHTML]="safeHtml"
  ></div>

  <div
    *ngIf="isBodyScrollable"
    class="modal__body__scroll-button-container"
  >
    <button
      mat-mini-fab
      class="modal__body__scroll-button-container__button"
      color="primary"
      [disabled]="!canScrollBodyUp"
      (click)="onScrollBodyUp()"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Scroll Up"
      >
        arrow_drop_up
      </mat-icon>
    </button>
    <button
      mat-mini-fab
      class="modal__body__scroll-button-container__button"
      color="primary"
      [disabled]="!canScrollBodyDown"
      (click)="onScrollBodyDown()"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Scroll Down"
      >
        arrow_drop_down
      </mat-icon>
    </button>
  </div>
</div>

<div class="modal-footer">
  <button
    class="btn btn-outline-dark"
    [disabled]="!canClose"
    type="button"
    (click)="onClose()"
  >
    {{ confirmButtonText }}
  </button>
</div>
