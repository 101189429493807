import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { Subject } from 'rxjs';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';

@Component({
  selector: 'app-coachtable',
  templateUrl: './coachtable.component.html',
  styleUrls: ['./coachtable.component.scss']
})
export class CoachtableComponent implements OnInit {
  dtOptions: any = {};
  mainData: any;
  loadingBar: boolean = true;
  maintable: any;
  columnsData:any;
  dtTrigger: Subject<any> = new Subject<any>();


  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    let param = {
      "page_name":"Trading Style Matching",
      "workshop_code":"MTB KL 2021/12"
    };

    this.dtOptions = {
      pagingType: 'full_numbers',
      order: [[ 1, "desc" ]],
      "autoWidth": true,
      scrollX:        true,
      scrollCollapse: true,
      scrollY:        '60vh',
      //responsive:true,
      fixedColumns:   {
        leftColumns: 2
      }
      /*"columnDefs": [
        { className: "td-small", "targets": "_all" },
        { "width": "400px", "targets": "_all" }
      ]*/
    };

    this.apiService.getReflectionsCoachData(param).subscribe(data => {
      //if (!this.authService.authCheckApi(data)) return;
      if (data.code === 0) {
        this.mainData = data.result;
        console.log(this.mainData);
        this.processData();
        this.dtTrigger.next();
        this.loadingBar = false;

      }


    });
  }

  processData() {
    this.maintable = Array();
    this.columnsData = Array();
    this.columnsData.push({name:"Given Name"});
    this.columnsData.push({name:"Student ID"});

    let studentids = this.mainData.feedback.map(feedback=>feedback.student_id).filter((value, index, self) => self.indexOf(value) === index);

    for (let k=0;k<this.mainData.questions.length;k++)
    {
      //if (this.mainData.questions[k].type!="RANGE")
        this.columnsData.push({name:this.mainData.questions[k].question});
    }

    //for (let j=0;j<this.mainData.students.length;j++)
    for (let j=0;j<studentids.length;j++)
    {
      let studentitem = Array();
      /*studentitem.push({value: this.mainData.students[j].given_name});
      studentitem.push({value:this.mainData.students[j].student_id});          */
      studentitem.push({value: "Test Name"});
      studentitem.push({value:studentids[j]});
      for (let k=0;k<this.mainData.questions.length;k++)
      {
        /*if (this.mainData.questions[k].type!="RANGE")
        {*/
          //let feedbackitem = this.mainData.feedback.filter(x => x.student_id == this.mainData.students[j].student_id && x.reflection_question_id == this.mainData.questions[k].id);
          let feedbackitem = this.mainData.feedback.filter(x => x.student_id == studentids[j] && x.reflection_question_id == this.mainData.questions[k].id);
          if (feedbackitem.length>0)
          {
            studentitem.push({value:feedbackitem[0].value});
          }
          else
            studentitem.push({value:"(none)"});
          //console.log(feedbackitem[0].value);
        //}
      }
      this.maintable.push(studentitem);

    }
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
