import { Component, OnInit,Input } from '@angular/core';
import { NgForm,FormControl, FormGroup, Validators,FormGroupDirective } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from "@angular/common";
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { GlobalVariableService } from '../../services/global-variable.service';
import { StorageService } from '../../services/storage.service';
import Swal from 'sweetalert2';
import {ErrorStateMatcher} from '@angular/material/core';

import {DategenService} from '../../services/dategen.service';

export class InstantErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null,
               form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-single-reflection-coach',
  templateUrl: './single-reflection-coach.component.html',
  styleUrls: ['./single-reflection-coach.component.scss']
})
export class SingleReflectionCoachComponent implements OnInit {


  @Input()
  PageDescription: string ="";

  @Input()
  FirstReadOnly: boolean = true;

  @Input()
  FirstReadOnlySize: number = 30;

  @Input()
  ReflectionTitle: string = "";

  @Input()
  SubmitText: string = "Submit";

  @Input()
  RouteBack: string = "/qcard/reflection1";

  @Input()
  RouteSubmit: string = "/qcard/mtbtradingstyle2";

  @Input()
  FilterObjects: any;

  PrimaryTradingStyleForm:any;

  validation_messages = {}

  matcher = new InstantErrorStateMatcher();

  private questions: any;
  private mbtradingdata: any;

  loadingBar: boolean = true;
  isMTB: boolean = false;
  isActive:boolean = true;
  testStar:any = null;


  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public route: Router,
    private dategenService: DategenService
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

    this.validation_messages = {}
    this.PrimaryTradingStyleForm = null;
  }

  onBack() {
    this.route.navigateByUrl(this.RouteBack);
  }


  dateProcess(datestring) {

    return this.dategenService.dateProcess(datestring,"medium","","Not Available");

  }

  updatePolarValue(formitem,e)
  {
    console.log("in polar value");
    this.PrimaryTradingStyleForm.get(formitem).setValue(e);
    console.log(this.PrimaryTradingStyleForm.get(formitem).value);
    this.PrimaryTradingStyleForm.get(formitem).markAsDirty();
  }


  processData() {
    if (this.mbtradingdata.length>0)
    {
      this.questions = Array.from(this.mbtradingdata[0].questions.reduce((m, t) => m.set(t.id, t), new Map()).values());


      let group={}
      this.questions.forEach(question=>{
        if (question.type=="RANGE")
          group['mtbquestion'+question.id] = new FormControl('',[Validators.min(0),Validators.required]);
        else
          group['mtbquestion'+question.id] = new FormControl('',Validators.required);
      })
      this.PrimaryTradingStyleForm = new FormGroup(group);



      this.questions.forEach(question=>{
        if (question.feedback.value !=null)
        {
          if (question.type=="SELECT")
          {
            this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.feedback.selection_id);
          }
          else
          this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.feedback.value);
          //if (question.feedback.coach_rating !=null)

        }
        else
        {
          if (question.type=="RANGE")
            this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).setValue(question.range.min);
        }

        let itemArray = Array();
        let item = {};
        item["type"] = "required";
        item["message"] = question.question + " is required";
        itemArray.push(item);

        if (question.type=="RANGE")
        {
          item["type"] = "min";
          item["message"] = question.question + " needs to be more than 0";
        }

        this.validation_messages['mtbquestion'+question.id] = itemArray;

      })
    }
    else
      this.isActive = false;

  }


  ngAfterViewInit() {
    this.apiService.getReflections().subscribe(data => {
      //if (!this.authService.authCheckApi(data)) return;
      if (data.code === 0) {
        this.isMTB = true;
        this.mbtradingdata = data.result.filter(x => x.description === this.PageDescription);
        console.log(this.mbtradingdata);
        this.processData();
      }
      else {
        this.isMTB = false;
      }
      this.loadingBar = false;

    });
  }

  onSubmit() {
    var dataparam = Array();
    let qparam;
    let param;


    this.questions.forEach(question=>{
      var qparam;
        if (question.type=="SELECT")
        {
          let vfilter = question.selection.filter(x => x.id == this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value)[0].value;
          qparam = {
            question_id: question.id,
            selection_id: this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value,
            value: vfilter
          }
        }
        else if (question.type=="RANGE")
        {
          qparam = {
            question_id: question.id,
            selection_id: null,
            value: +this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value
          }
        }
        else {
          qparam = {
            question_id: question.id,
            selection_id: null,
            value: this.PrimaryTradingStyleForm.get('mtbquestion'+question.id).value
          }
        }
        dataparam.push(qparam);


    })

    param =
    {
      page_id: this.mbtradingdata[0].id,
      feedbacks: dataparam

    }

    this.apiService.updateFeedback(param).subscribe(data => {
      if (data.code === 0) {
        // If there are requested fields

        Swal.fire({
          icon: 'success',
          title: this.PageDescription+' updated',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false
        });

        this.route.navigate([this.RouteSubmit]);
      }
      else {
        var errorMessages = '';

        // If there are errors
        if (!!data.errors) {
          // Get array of error fields
          var arrayOfFields = Object.keys(data.errors);
          // Loop the array of error fields
          for (var i = 0; i < arrayOfFields.length; i++) {
            // Get the array of messages for each field
            var arrayOfMessages = data.errors[arrayOfFields[i]];
            for (var j = 0; j < arrayOfMessages.length; j++) {
              errorMessages += arrayOfMessages[j] + '\n';
            }
          }
        }

        Swal.fire({
          title: 'Error, kindly inform administrator',
          text: errorMessages,
          icon: 'warning',
          confirmButtonText: 'Okay',
          heightAuto: false
        });
      }
    });
  }

}
