<mat-spinner *ngIf="loadingBar" style="margin-top:10%;margin-left:42%;"></mat-spinner>

<div *ngIf="!isActive">
  <h5 class="">
    <span>This reflection is not ready yet.</span>
  </h5>
</div>

<div *ngIf="!isMTB&&!loadingBar&&isActive">
  <h5 class="">
    <span>Kindly note that this reflection is only meant for MTB members,
      if you have received this in error, contact the Beyond Insights team.</span>
  </h5>
</div>
<mat-card *ngIf="!loadingBar&&isMTB&&isActive" style="padding-top:1.5rem !important;">
  <!--<mat-card-content class="center-content">
    <button mat-raised-button class="back-button" (click)="onBack()">Back</button>
  </mat-card-content>-->
  <mat-card-header class="center-content">
    <mat-card-title [innerHTML]="ReflectionTitle">
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="center-content" >
    <form [formGroup]="PrimaryTradingStyleForm" (ngSubmit)="onSubmit()">
      <div>
        <div *ngFor="let qs of questions; let i = index" >
          <div class="coachratings">
            Rating&nbsp;:&nbsp;
            <div>
              <div *ngIf="qs.feedback.coach_rating==null" style="font-weight:300;">
                Not rated
              </div>
              <mat-chip-list aria-label="Coachrating" *ngIf="qs.feedback.coach_rating!=null">
                <mat-chip class="ratingnumber{{qs.feedback.coach_rating}}" selected>{{qs.feedback.coach_rating}}</mat-chip>
              </mat-chip-list>
            </div>
           </div>
           <div class="coachratingssmall" *ngIf="qs.feedback.coach_rating!=null">
             Last Updated &nbsp;:&nbsp; <span style="font-weight:300;">{{dateProcess(qs.feedback.coach_updated_at)}}</span>
           </div>
          <mat-form-field appearance="outline" *ngIf="qs.visible==null||qs.visible!=false" color="primary" style="width:100%;">
            <mat-label>{{qs.question}}</mat-label>
            <mat-select matNativeControl required formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='SELECT'" (change)="onChange($event.target.value)">
              <mat-option *ngFor="let q of qs.selection" [value]=q.id>{{q.value}}</mat-option>
            </mat-select>
            <textarea rows="4" matInput placeholder=""
            formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXTAREA'&&(i>0||!FirstReadOnly)"></textarea>
            <textarea [rows]="FirstReadOnlySize" matInput placeholder=""
            formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXTAREA'&&i==0&&FirstReadOnly" [readonly]="true"></textarea>
            <input matInput placeholder="" formControlName="{{'mtbquestion'+qs.id}}" *ngIf="qs.type=='TEXT'">
            <input type="number" formControlName="{{'mtbquestion'+qs.id}}" matInput required *ngIf="qs.type=='RANGE'" [errorStateMatcher]="matcher" hidden>
            <mat-error *ngFor="let validation of validation_messages['mtbquestion'+qs.id]">
              <span class="error-message" *ngIf="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).hasError(validation.type) &&
                        (PrimaryTradingStyleForm.get('mtbquestion'+qs.id).dirty ||
                        PrimaryTradingStyleForm.get('mtbquestion'+qs.id).touched)">
                {{validation.message}}
              </span>
            </mat-error>
            <mat-slider thumbLabel [tickInterval]="qs.range.step" [min]="qs.range.min" [max]="qs.range.max" [value]="PrimaryTradingStyleForm.get('mtbquestion'+qs.id).value" (change)="updatePolarValue('mtbquestion'+qs.id, $event.value)" *ngIf="qs.type=='RANGE'"></mat-slider>
          </mat-form-field>
          <!--<mat-card class="coachcard">
            <mat-card-header class="coachheader">
              <mat-card-title>
                Coach Rating
              </mat-card-title>
            </mat-card-header>

          </mat-card>-->
          <div *ngIf="i<(questions.length-1)">
            <br><br>
            <mat-divider [inset]="true"></mat-divider>
            <br><br>
          </div>
        </div>

      </div>
      <div>
        <button mat-raised-button color="primary" class="submit-button"
          [disabled]="!PrimaryTradingStyleForm.valid">{{SubmitText}}</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
