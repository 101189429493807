import { AbstractControl,ValidatorFn,FormGroupDirective,ValidationErrors } from '@angular/forms'

/** A hero's name can't match the given regular expression */

function hasDuplicates(arr) {
    return new Set(arr).size !== arr.length;
}

export function uniquecomboValidator(questions: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {  
        let jCheck = Array();
        //console.log(questions);
        let controlkeys = Object.keys(control.value);
        //console.log(ApiKeys);
        for (let i = 0; i < controlkeys.length; i++) {
            
            if (control.value[controlkeys[i]]!="")
            {
                
                let qid = controlkeys[i].replace('mtbquestion','');
                let question = questions.filter(x => x.id == qid);

                console.log(question);
                if (question.length>0)
                {                    
                    if (question[0].type=="SELECT")
                    {
                        let selectvalue = question[0].selection.filter(x => x.id == control.value[controlkeys[i]])
                        if (selectvalue.length>0)
                        {
                            jCheck.push(selectvalue[0].value);                        
                        }
                    }                    
                }

                
            }
        }        
        console.log(hasDuplicates(jCheck));
        

        return (hasDuplicates(jCheck)? { uniquecombo: true } :null);
    };
  }
