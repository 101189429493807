import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective {

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    console.log("in directive");
    this.el.nativeElement.focus();
  }

}
