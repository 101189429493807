<mat-spinner *ngIf="loadingBar" style="margin-top:10%;margin-left:42%;"></mat-spinner>

<div [hidden]="loadingBar">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="display compact cell-border hover tablesetting">
        <thead>
            <tr>
                <th *ngFor="let col of columnsData" style="background-color:white;">
                    {{col.name}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let mt of maintable">            
                <td *ngFor="let item of mt" class="td-small">
                    <div class="cellstyle">
                        {{item.value}}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>