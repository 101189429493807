import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { NgForm,FormControl, FormGroup, Validators,ValidatorFn,FormGroupDirective,ValidationErrors } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { GlobalVariableService } from '../../services/global-variable.service';
import { StorageService } from '../../services/storage.service';
import Swal from 'sweetalert2';
import {ErrorStateMatcher} from '@angular/material/core';
import {uniquecomboValidator} from '../../validators/uniquecombo.validator';
import {DategenService} from '../../services/dategen.service';
import {ErrorhandleapiService} from '../../services/errorhandleapi.service';
import {MatSort} from '@angular/material/sort';
import {Sort} from '@angular/material/sort';
import {MatSortable} from '@angular/material/sort';
import { isDateSelectionValid } from '@fullcalendar/core';



@Component({
  selector: 'app-single-reflection-table',
  templateUrl: './single-reflection-table.component.html',
  styleUrls: ['./single-reflection-table.component.scss']
})
export class SingleReflectionTableComponent implements OnInit {

  @Input()
  PageID: number = -1;

  @Input()
  ReflectionTitle: string = "";

  @Input()
  SubmitText: string = "Submit";

  @Input()
  RouteBack: string = "/reflection/reflection1";

  @Input()
  RouteSubmit: string = "/qcard/mtbtradingstyle2";

  @Input()
  CoachComments: boolean = false;

  @Input()
  CoachCommentsTitle: string = "Facilitators Comment";

  @Input()
  LineAfterQuestion: any = [];

  @Input()
  TableHeaders: any = [
    ['Challenges','Traits']
  ]

  @ViewChild(MatSort) sort: MatSort;

  private questions: any;
  private mbtradingdata: any;
  private questionsGroup: any;
  private pagedesc:string="";
  private oriquestions: any;

  loadingBar: boolean = true;
  isMTB: boolean = false;
  isActive:boolean = true;
  isSubmit: boolean = true;
  displayedColumns: string[] = ['task','result'];
  StandardHeaders: string[] = ['Challenges','Traits'];
  questionComments: any =
  [
    ["#line#","drawline"],
    ["#nocomment#","nocomment"],
    ["#normalfont#","normalfont"],
    ["#readonly#","readonly"],
    ["#optional#","optional"],
  ];


  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public route: Router,
    private dategenService: DategenService,
    private errorapi:ErrorhandleapiService,
  ) { }

  ngOnInit(): void {


  }

  processHeader(index,offset) {
    if (this.TableHeaders.length==0)
      return this.StandardHeaders[offset];
    if (this.TableHeaders.length>0 && this.TableHeaders.length-1 >= index )
      return this.TableHeaders[index][offset];
    if (this.TableHeaders.length>0 && this.TableHeaders.length-1 < index )
      return this.TableHeaders[0][offset];
  }

  sortData(index,sort: Sort) {
    console.log("sort change emitted");
    const data = this.questionsGroup[index]["questions"].slice();
    console.log(data);
    if (!sort.active || sort.direction === '') {
      console.log("sort not active and sort direction empty");
      this.questionsGroup[index]["questions"] = data;
      return;
    }

    this.questionsGroup[index]["questions"] = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'result': return this.compareQuestion(a,b,isAsc);
        //case 'result': return this.compare(this.processNumber(a.feedback.value), this.processNumber(b.feedback.value), isAsc);
        default: return 0;
      }
    });

    console.log("to sort");

    console.log(this.questionsGroup[0]["questions"]);

  }

  processNumber(value)
  {
    if (value==null) return -1;

    let matches = value.match(/(\d+)/);
    if (matches)
      return Number(matches[0]);
    else
      return -1;
  }

  compareQuestion(a,b,isAsc:boolean)
  {
    console.log("Comparing")
    console.log(a.feedback.value);
    console.log(b.feedback.value);
    if (this.processNumber(a.feedback.value) < this.processNumber(b.feedback.value))
      return (-1 * (isAsc ? 1 : -1))
    else if (this.processNumber(a.feedback.value) > this.processNumber(b.feedback.value))
      return (1 * (isAsc ? 1 : -1))
    else
    {
      console.log("Going to check ID");
      console.log(a.id);
      console.log(b.id);
      if (a.id < b.id)
        return (1 * (isAsc ? 1 : -1))
      else
        return (-1 * (isAsc ? 1 : -1))
    }
  }

  compare(a: string|number, b: string|number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnDestroy(): void {
  }

  onBack() {
    this.route.navigateByUrl(this.RouteBack);
  }


  processQuestionsFormatting() {
    if (this.mbtradingdata[0].name.includes("#coach#"))
    {
      this.CoachComments = true;
      this.mbtradingdata[0].name = this.mbtradingdata[0].name.replace("#coach#","");
    }

    for (let i=0;i<this.questions.length;i++)
    {

      for (let j=0;j<this.questionComments.length;j++)
      {
        if (this.questions[i].question.includes(this.questionComments[j][0]))
        {
          this.questions[i].question = this.questions[i].question.replace(this.questionComments[j][0],"");
          this.questions[i][this.questionComments[j][1]] = true;
        }
      }

      if (this.questions[i].type=="TITLE")
        this.questions[i].nocomment = true;

    }
  }

  processQuestionsTable()
  {
    this.questionsGroup = Array();
    let arrayMarker = -1;

    for (let i=0;i<this.questions.length;i++)
    {
      if ((i==0) && this.questions[i].type!="TITLE")
      {
        arrayMarker++;
        this.questionsGroup[arrayMarker] = Array();
        this.questionsGroup[arrayMarker]["question"] = "";
        this.questionsGroup[arrayMarker]["questions"] = Array();
      }

      if (this.questions[i].type=="TITLE")
      {
        if (arrayMarker!=-1)
        {
          this.questionsGroup[arrayMarker]["questions"] = this.questionsGroup[arrayMarker]["questions"].sort((a, b) => {
            //return this.compare(this.processNumber(a.feedback.value), this.processNumber(b.feedback.value),false);
            return this.compareQuestion(a,b,false);
          });
        }
        arrayMarker++;
        this.questionsGroup[arrayMarker] = this.questions[i];
        this.questionsGroup[arrayMarker]["questions"] = Array();
      }
      else
        this.questionsGroup[arrayMarker]["questions"].push(this.questions[i]);
    }

    this.questionsGroup[arrayMarker]["questions"] = this.questionsGroup[arrayMarker]["questions"].sort((a, b) => {
      return this.compare(this.processNumber(a.feedback.value), this.processNumber(b.feedback.value),false);
    });

  }

  processData() {
    if (this.mbtradingdata.length>0)
    {
      this.pagedesc = this.mbtradingdata[0].description;
      this.questions = Array.from(this.mbtradingdata[0].questions.reduce((m, t) => m.set(t.id, t), new Map()).values());
      this.processQuestionsFormatting();
      this.processQuestionsTable();

    }
    else
      this.isActive = false;
  }


  ngAfterViewInit() {
    //console.log("this sort");
    //console.log(this.sort);

    this.apiService.getReflections().subscribe(data => {
      //if (!this.authService.authCheckApi(data)) return;
      if (data.code === 0) {
        console.log(this.sort);
        /*const sortState: Sort = {active: 'result', direction: 'desc'};
        console.log(sortState);
        console.log(this.isMTB);
        console.log(this.sort);
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);*/
        this.isMTB = true;
        this.mbtradingdata = data.result.filter(x => x.id == this.PageID);
        console.log(this.mbtradingdata);
        this.processData();
      }
      else {
        this.isMTB = false;
      }
      this.loadingBar = false;

    });
  }

}
