import { Component, Input, ElementRef, OnChanges } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnChanges {

  @Input('text') text: string  = "";
  @Input('maxLength') maxLength: number = 100;
  @Input('showToggleButton') showToggleButton: boolean = false;

  currentText: string ="";
  public isCollapsed: boolean = true;

  constructor(
    private elementRef: ElementRef
  ) { }

  toggleView() {
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    if (this.text.length <= this.maxLength) {
      this.currentText = this.text;
      this.isCollapsed = false;
      this.showToggleButton = false;
      return;
    }

    if (this.isCollapsed == true) {
      this.currentText = this.text.substring(0, this.maxLength) + "...";
    } else if (this.isCollapsed == false) {
      this.currentText = this.text;
    }

  }

  validateSource(s) {
    if (typeof s !== 'string') {
      return false;
    } else {
      return true;
    }
  }

  ngOnChanges(): void {
    if (!this.validateSource(this.text)) {
      //throw 'Source must be a string.';
      console.error('Source must be a string.');
    }
    else {
      this.determineView();
    }
  }

}
