import { Component, Input, OnInit } from '@angular/core';
import {
  widget,
  IChartingLibraryWidget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
  EntityId
} from '../../../assets/charting_library';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import 'rxjs/add/operator/distinctUntilChanged';
import {TradingViewDataProvider} from '../../shared/trading-view-data-provider'
import {TradingViewEodProvider} from '../../shared/trading-view-eod-provider'
import {StudentPortalSaveLoad} from '../../shared/student-portal-save-load'
import { distinct,debounceTime,distinctUntilChanged,takeLast,map,throttleTime,switchMap } from 'rxjs/operators';
import { _ } from 'ag-grid-community';
import { from } from 'rxjs';

@Component({
  selector: 'app-stockchart',
  templateUrl: './stockchart.component.html',
  styleUrls: ['./stockchart.component.scss']
})
export class StockchartComponent implements OnInit {

  autoSave:any;
  drawingIndicators:any;
  private static tvwidget:any;
   tradingprovider:any;
   private symbols:any;
  //private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'Bitfinex:BTC/USD';
    private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'USA:MSFT';
    private _interval: ChartingLibraryWidgetOptions['interval'] = '1D' as ResolutionString;
    // BEWARE: no trailing slash is expected in feed URL
    private _datafeedUrl = 'https://demo_feed.tradingview.com';
    private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '/assets/charting_library/';
    private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';
    private _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] = '1.1';
    private _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';
    private _userId: ChartingLibraryWidgetOptions['user_id'] = 'public_user_id';
    private _disabled_features: ChartingLibraryWidgetOptions['disabled_features'] = [];
    private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
    private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
    private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
    private static _tvWidget: IChartingLibraryWidget | null = null;
    private _container: ChartingLibraryWidgetOptions['container'] = 'tv_chart_container';
    private _autoSave: boolean = true;
    private _width:any= "100%";
    private _height:any= "100%";
    private _loadLastChart:boolean = true;
    private _inputstream$:any = null;
    private _templateSubscription: any;
    private _templateFramework:any = null;
    public _className:any = "app-tv-chart-container";    
    private _dataSubscription:any = null;


    private _BIIndicators:any = [
      {
        name:'Moving Average Exponential',
        length:20,
        color:'#FF5252',
      },
      {
        name:'Moving Average Exponential',
        length:50,
        color:'#4CAF50',
      },
      {
        name:'Moving Average Exponential',
        length:200,
        color:'#9C27B0',
      },
      {
        name:'Volume'
      },
      {
        name:'Average True Range',
        color:'#FF0000',
      },
    ]


    @Input()
    set container(container: ChartingLibraryWidgetOptions['container']) {
        this._container = container || this._container;
    }

    @Input()
    set symbol(symbol: ChartingLibraryWidgetOptions['symbol']) {
        this._symbol = symbol || this._symbol;
    }

    @Input()
    set interval(interval: ChartingLibraryWidgetOptions['interval']) {
        this._interval = interval || this._interval;
    }

    @Input()
    set datafeedUrl(datafeedUrl: string) {
        this._datafeedUrl = datafeedUrl || this._datafeedUrl;
    }

    @Input()
    set libraryPath(libraryPath: ChartingLibraryWidgetOptions['library_path']) {
        this._libraryPath = libraryPath || this._libraryPath;
    }

    @Input()
    set chartsStorageUrl(chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']) {
        this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
    }

    @Input()
    set chartsStorageApiVersion(chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']) {
        this._chartsStorageApiVersion = chartsStorageApiVersion || this._chartsStorageApiVersion;
    }

    @Input()
    set clientId(clientId: ChartingLibraryWidgetOptions['client_id']) {
        this._clientId = clientId || this._clientId;
    }

    @Input()
    set userId(userId: ChartingLibraryWidgetOptions['user_id']) {
        this._userId = userId || this._userId;
    }

    @Input()
    set fullscreen(fullscreen: ChartingLibraryWidgetOptions['fullscreen']) {
        this._fullscreen = fullscreen || this._fullscreen;
    }

    @Input()
    set disabled_features(disable_features: ChartingLibraryWidgetOptions['disabled_features']) {
        this._disabled_features = disable_features || this.disabled_features;
    }

    @Input()
    set disabled(autosize: ChartingLibraryWidgetOptions['autosize']) {
        this._autosize = autosize || this._autosize;
    }

    @Input()
    set containerId(containerId: ChartingLibraryWidgetOptions['container_id']) {
        this._containerId = containerId || this._containerId;
    }

    @Input()
    set autosave(autoSave: boolean) {
        this._autoSave = autoSave || this._autoSave;
    }

    @Input()
    set ClassDesign(cName: string) {
        this._className = cName || this._className;
    }

    @Input()
    set inputstream(ts$: any) {
        this._inputstream$ = ts$;
    }

    @Input()
    set templateframework(framework: any) {
        this._templateFramework = framework;
    }

    @Input()
    set loadlastchart(llc: boolean) {
        this._loadLastChart = llc && this._loadLastChart;
    }

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private storageService:StorageService,
        private router:Router
      ) {
      }

    ngOnInit() {
    }

    ngOnChanges(){
        this.ngOnDestroy();


        this.ngAfterViewInit();
    }

    processTemplate(template:any,drawingSymbol:any){
     if (drawingSymbol.shape=="horizontal_line")
     {
      let widget = StockchartComponent._tvWidget;
      if (widget != null)
      {
        let isExist = false;
        widget.activeChart().getAllShapes().forEach(({ id,name }) =>
        {
          if (widget!=null)
          {
            let shape = widget.activeChart().getShapeById(id);
            let properties = shape.getProperties();

            if (properties.text==drawingSymbol.text)
            {
              isExist = true;
            }
          }
        });

      }
     }
    }

    processTemplateStream(template: any){
      console.log(template);
      /*
      for (let i=0;i<symbolIndicator.length;i++)
        this.processTemplate(template,symbolIndicator[i]);
      */
    }

    processLines(tvWidget:any,templateFramework:any) {
      let widget = StockchartComponent._tvWidget;
      const from = Date.now() / 1000 * 24 * 3600;
      console.log(templateFramework);
      console.log(widget==null);
      templateFramework.map( template => {
        console.log(widget!=null);
        if (widget!=null)
        {
          console.log(from);
          widget.activeChart().createShape(
            {
              time: 1514764800, 
              price:template.default
            },
            {
              shape: 'horizontal_line',
              text:template.name,
              zOrder:'top',
              disableSave:true,
              disableUndo: true,
              overrides:template.overrides
            }
          );
        }
        
        console.log(template);
      })
    }

    ngAfterViewInit() {

      if (StockchartComponent._tvWidget !== null) {
          StockchartComponent._tvWidget.remove();
          StockchartComponent._tvWidget=null;
      }

      function getLanguageFromURL(): LanguageCode | null {
          const regex = new RegExp('[\\?&]lang=([^&#]*)');
          const results = regex.exec(location.search);
          return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
      }

      this.tradingprovider = new TradingViewEodProvider(this.apiService,this.authService);
      const widgetOptions: ChartingLibraryWidgetOptions = {
          symbol: this._symbol,
          datafeed: this.tradingprovider,
          interval: this._interval,
          container: this._container,
          library_path: this._libraryPath,
          locale: getLanguageFromURL() || 'en',
          disabled_features: this._disabled_features,
          enabled_features: ['study_templates','side_toolbar_in_fullscreen_mode'],
          charts_storage_url: this._chartsStorageUrl,
          charts_storage_api_version: this._chartsStorageApiVersion,
          client_id: this._clientId,
          /*
          width:600,
          height:600,
          */
          user_id: this._userId,
          fullscreen: this._fullscreen,
          autosize: true,
          save_load_adapter: new StudentPortalSaveLoad(this.storageService),
          load_last_chart: this._loadLastChart,
          auto_save_delay: 1,
          time_frames:[
              { text: "1y", resolution: "1D" as TradingView.ResolutionString, description: "1 Year" },
              { text: "5y", resolution: "1W" as TradingView.ResolutionString, description: "5 Years"},
          ],
          debug: false
      };

      StockchartComponent._tvWidget = new widget(widgetOptions);
      let tvWidget = StockchartComponent._tvWidget;

      tvWidget.onChartReady(() => {
        let allStudies = tvWidget.activeChart().getAllStudies();
        const chartData$ = from(tvWidget.activeChart().exportData({ includeTime: false, includedStudies: [] }));
        console.log(chartData$);
        this._dataSubscription = chartData$.pipe(            
          distinctUntilChanged(),
        ).subscribe(
          (data: any) => console.log(data)
        );
        
        if (this._inputstream$ !=null)
        {
          if (this._templateFramework!=null)
          {
            console.log("tf not null");
            this.processLines(tvWidget,this._templateFramework);
          }
          if (this._templateSubscription!=null)
          {
            this._templateSubscription.unsubscribe();
            this._templateSubscription = null;
          }
          this._templateSubscription = this._inputstream$.pipe(            
            distinctUntilChanged(),
          ).subscribe(
            (template: any) => this.processTemplateStream(template)
          );
        }

        this._BIIndicators.forEach(({ name, length, color }) => {
          for (let i=0;i<allStudies.length;i++)
          {
            let study = tvWidget.activeChart().getStudyById(allStudies[i].id).getInputValues();
            if (length!=undefined)
            {
              let s = study.find(x => x.id == 'length');
              if (s==undefined) continue;
              if ((allStudies[i].name == name) && (s.value == length))
              {
                return;
              }

            }
            else
            {
              if ((allStudies[i].name == name))
                return;
            }

          }
          if (length!=undefined)
          {
            tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, [length],{"plot.color": color,"plot.linewidth":2});
          }
          else
          {
            if (name=="Volume")
            {
              tvWidget.activeChart().createStudy('Volume', true, false, []);
              return;
            }

            tvWidget.activeChart().createStudy('Average True Range', false, false, [14], {"plot.m_height": 50})
            .then(function(response){
              let panes = tvWidget.chart().getPanes();
              panes.forEach((pane) =>
              {
                if (!pane.hasMainSeries())
                  pane.setHeight(50);
              });
            });
          }

      });

          if (this._autoSave) {
            this.autoSave = tvWidget.subscribe("onAutoSaveNeeded",function() {
                tvWidget.saveChartToServer(undefined,undefined,{"defaultChartName":"BISave"});
            });
          }

          this.drawingIndicators = tvWidget.subscribe("drawing_event",function(event_id,event_type) {
            console.log(event_id);
            console.log(event_type);
            let shape = tvWidget.activeChart().getShapeById(event_id as EntityId);
            console.log(shape.getProperties());
          });

      });

    }

    ngOnDestroy() {
        if (this._templateSubscription!=null)
        {
          this._templateSubscription.unsubscribe();
          this._templateSubscription = null;
        }
        if (StockchartComponent._tvWidget !== null) {
          if (this._autoSave)
            StockchartComponent._tvWidget.unsubscribe('onAutoSaveNeeded', this.autoSave);
          StockchartComponent._tvWidget.unsubscribe('drawing_event', this.drawingIndicators);
          StockchartComponent._tvWidget.remove();
          delete this.tradingprovider;
          this.tradingprovider = null;

          //this.autoSave.unsubscribe();
          StockchartComponent._tvWidget= null;


        }


    }

}
