// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  testing:false,
  // apiUrl: 'https://localhost.beyondinsights.net:9091/api/',
  apiUrl: 'https://qa.student.beyondinsights.net/api/',
  nodeapiUrl:'',
  stockapiUrl: 'https://9gad047g2f.execute-api.ap-southeast-1.amazonaws.com/qa/',
  nestApiUrl: 'https://qa.api.beyondinsights.net/',
  facebookAppId: '390294785342157',
  stripePublishKey:'pk_test_51JcRtxB6U2rzv1FWQEIgFLDXPZ58DZujKvYLWB7ce1G4QZFXvipeSYTO6ohrZUVeX6nscNscxro6ZkM4Ip8nUWdk004jRR8rT6',
  googleLogin:'480157565519-oeu5vtfbbrvgtgs4mk0vgi9qi6f8b8v8.apps.googleusercontent.com',
  facebookLogin:'466499084478293',
  cookiedomain:'qa.student.beyondinsights.net',
  supersetDomain: 'http://host.docker.internal:8088',
};
