import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandleapiService {

  constructor() { }

  handleError( errorsitem: any) {
    var errorMessages = '';

    // If there are errors
    if (!!errorsitem) {
      // Get array of error fields
      var arrayOfFields = Object.keys(errorsitem);
      // Loop the array of error fields
      for (var i = 0; i < arrayOfFields.length; i++) {
        // Get the array of messages for each field
        var arrayOfMessages = errorsitem[arrayOfFields[i]];
        for (var j = 0; j < arrayOfMessages.length; j++) {
          errorMessages += arrayOfMessages[j] + '\n';
        }
      }
    }

    console.log("am in this error handler");


    Swal.fire({
      title: 'Error, kindly inform administrator',
      text: errorMessages,
      icon: 'error',
      confirmButtonText: 'Okay',
      heightAuto: false
    });

    return throwError(
      'Something bad happened; please try again later.');

  }
}
